import React from "react";

const Showcase = () => {
  const features = [
    {
      header: "AI-Powered Writing Assistant",
      desc: "Unleash your creativity with Ghost Writr's AI, a companion that molds to your writing style, offering personalised suggestions from the first word to the final full stop. Transform your creative process with an assistant that understands you.",
      image: "bg-showcase-3.jpg",
      bgColor: "#a0e1e1",
    },
    {
      header: "Fully Responsive Design",
      desc: "Write anywhere, anytime, on any device. Ghost Writr's responsive design flawlessly adapts to your mobile, tablet, or desktop, ensuring a seamless and distraction-free writing experience wherever inspiration strikes.",
      image: "bg-showcase-1.jpg",
      bgColor: "#ffc091",
    },
    {
      header: "Bootstrap 5 Integration",
      desc: "Experience the pinnacle of modern writing with Ghost Writr's integration of Bootstrap 5. Enjoy a sleek, intuitive interface that guarantees a smooth creation and editing process on all your devices, powered by the latest web technologies.",
      image: "bg-showcase-2.jpg",
      bgColor: "#163300",
    },
  ];

  return (
    <section className="showcase-section">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <h2 className="text-center text-md-start">
              We have chosen some cool tech!
            </h2>
            <p>
              Check out the latest and great tech we have added to the 👻 WRITR
              ecosystem.
            </p>
            <div className="d-flex align-items-center justify-content-center justify-content-md-start">
              <button
                className="btn btn-primary me-2"
                type="button"
                data-bs-target="#showcaseCards"
                data-bs-slide="prev"
              >
                <i
                  className="bi bi-chevron-left"
                  style={{ fontSize: "2rem", color: "white" }}
                ></i>
              </button>
              <button
                className="btn btn-primary"
                type="button"
                data-bs-target="#showcaseCards"
                data-bs-slide="next"
              >
                <i
                  className="bi bi-chevron-right"
                  style={{ fontSize: "2rem", color: "white" }}
                ></i>
              </button>
            </div>
          </div>

          <div className="col-md-5">
            <div
              id="showcaseCards"
              className="carousel slide carousel-fade"
              data-bs-ride="carousel slide"
            >
              <div className="carousel-inner">

                {features.map((feature, index) => (
                  <div
                    key={index}
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                    data-bs-interval="10000"
                  >
                    <div className="d-flex justify-content-center">
                      <div
                        className={`card card-cover overflow-hidden rounded-4 shadow-lg ${ feature.image ? "text-bg-dark" : "" }`}
                        style={{
                          backgroundImage: feature.image ? `url('/images/${feature.image}')` : ``,
                          width: "90%", // Cards take up 90% of their column
                          backgroundColor: feature.bgColor
                        }}
                      >
                        <div className="d-flex flex-column h-100 p-3 pb-3  text-white">
                          <h3 className="pt-5 mt-3 mb-3 display-4 lh-1 fw-bold showcaseTitle text-shadow-1">
                            {feature.header}
                          </h3>
                          <p className="lead">{feature.desc}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Showcase;
