import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const CTASection = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <section className="cta-section text-center">
      <div className="container">
        <h2 className="mb-4">Dive into the future of writing with 👻 Writr!</h2>
        <p className="mb-5">Sign up now and be part of our beta testing community. Help shape the future of content creation and transform your writing journey. Join us now!</p>
        <button onClick={() => loginWithRedirect()} className="btn btn-lg btn-primary">Join Our Beta Community</button>
      </div>
    </section>
  );
};

export default CTASection;
