import React from "react";

const WritingModules = ({ modulesActive }) => {

  const loadModule = (name) => {
    // This will cause a full page reload to the specified path
    window.location.href = `/${name}`;
    // console.log(name);
  };
 
  return (
    <div className="container">
      <div className="container px-2 py-3">
      
        <div className="container my-5">
          <div className="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg hero-wlp">
            <div className="col-lg-7 p-3 p-lg-5 pt-lg-3">
              <h1 className="display-4 fw-bold lh-1 text-body-emphasis moduleHeader">
                Welcome to 👻 Writr modules!
              </h1>
              <p className="lead">
                Here you will find a selection of modules with specific writing
                themes. As more module become avaialble they will appear here.
              </p>
            </div>
            <div
              className="col-lg-4 offset-lg-1 p-0 overflow-hidden shadow-lg"
              style={{ height: "300px" }}
            >
              <img
                className="rounded-lg-3 modulesHero"
                src="/images/hero-image-modules.png"
                alt=""
                width="1020"
              />
            </div>
          </div>
        </div>

        <div className="container px-4 py-1" id="custom-cards">
          <div className="row  row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5">
            {modulesActive.map((option, index) => (
              <div
                className="col"
                key={index} // key should be placed here
              >
                <div
                  className="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg"
                  style={{ backgroundImage: `url('/images/${option.image}')` }}
                >
                  <div className="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                    <h3 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">
                      {option.header}
                    </h3>
                    <h5 style={{paddingBottom: "4rem"}}>{option.desc}</h5>
                    <ul className="d-flex list-unstyled mt-auto">
                      <li className="me-auto">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => loadModule(option.route)}
                          disabled={!option.active}
                        >
                          Launch
                        </button>
                      </li>
                      <li className="d-flex align-items-center me-3">
                        <i className="bi bi-code"></i>
                        <small style={{ paddingLeft: "5px" }}>
                          {option.version}
                        </small>
                      </li>
                      <li className="d-flex align-items-center">
                        <i
                          className={` bi ${
                            option.active
                              ? "bi-check-circle-fill"
                              : "bi-check-circle"
                          }`}
                        ></i>
                        <small style={{ paddingLeft: "5px" }}>
                          {option.active ? "Open" : "Closed"}
                        </small>
                      </li>
                    </ul>
                  </div>
                  {!option.active && (
                    <div className="overlay">
                      {/* You can replace this div with an image or any other content */}
                      <p className="overlay-text">COMING SOON</p>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WritingModules;
