import Slider from "./components/steps/Slider";
import TextBox from "./components/steps/TextBox";
import CardSelector from "./components/steps/CardSelector";

export const totalSteps = 5; // Define the total number of steps in the process
export const requireAuth = true;

export const stepsConfig = ({
  toneOptions,
  sentimentOptions,
  audienceOptions,
  parentToggle
}) => [
  {
    component: TextBox,
    props: {
      label: "Article Topic",
      description: "What can I help you write today?",
      placeholder: "e.g. The Office US vs UK TV show and its impact on society",
      animationClass: "slide-in",
      parentToggle: parentToggle,
      styleName:"topic",
      value: "",
      module: "articles"
    }
  },
  {
    component: CardSelector,
    props: {
      title: "Article Tone",
      description: "What is the tone of the article like?",
      options: toneOptions,
      animationClass: "slide-in",
      styleName:"tone",
      value:"",
      module: "articles"
    },
  },
  {
    component: CardSelector,
    props: {
      title: "Article Sentiment",
      description: "Why are you writing this article?",
      options: sentimentOptions,
      animationClass: "slide-in",
      styleName:"sentiment",
      value: "",
      module: "articles"
    }
  },
  {
    component: CardSelector,
    props: {
      title: "Target Audience",
      description: "Who are you writing this for?",
      options: audienceOptions,
      animationClass: "slide-in",
      styleName:"audience",
      value: "",
      module: "articles"
    }
  },
  {
    component: Slider,
    props: {
      label: "Article Sections",
      description: "Slide to select the number of sections for this article.",
      min: 3,
      max: 6,
      animationClass: "slide-in",
      styleName:"sections",
      module: "articles"
    }
  },
  // Add other steps as needed
];
