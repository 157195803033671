import React from "react";
// Importing new and updated components
import NavBar from "./components/NavBarLite";
import HeroSection from "./components/HeroSection";
import FeaturesSection from "./components/FeaturesSection";
import AboutSection from "./components/AboutSection";
// import Testimonials from "./components/Testimonials";
import Showcase from "./components/Showcase";
import CTASection from "./components/CTASection";
import MediumArticles from "./components/MediumArticles"; // Adjust the path as necessary
import Footer from "./components/FooterLite";

import "./LandingPage.css"; // Import as a module

const LandingPage = () => {
  // Auth0 Hook for authentication
  // const { loginWithRedirect } = useAuth0();

  return (
    <div>
      <NavBar />
      <HeroSection />
      <FeaturesSection />
      <CTASection />
      {/* <Testimonials /> */}
      <Showcase />
      <MediumArticles />
      <AboutSection />

      
      <Footer />
    </div>
  );
};

export default LandingPage;
