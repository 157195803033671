import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const AboutSection = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <section className="about-section text-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h2>Try 👻 Writr for Free</h2>
            <p className="lead">
              Empower your writing journey and break through barriers like
              writer's block and technical complexities. With Ghost Writr,
              experience the freedom of creativity through our AI-enhanced
              writing tools. Try Ghost Writr for Free and unlock your full
              potential as a writer today.
            </p>
            {/* Optional: Include more detailed paragraphs or bullet points here */}
            <a
              href="#signup"
              className="btn btn-primary btn-lg"
              onClick={() => loginWithRedirect({ screen_hint: "signup" })}
            >
              Start Writing Now
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
