// Loading.js
import React from 'react';
import useModuleState from "../hooks/useModuleState";


const Loading = ({ module }) => {
  const moduleState = useModuleState(module);
  const {
    loading
  } = moduleState;
  
  return (
    <div className={` loading-overlay ${loading ? "show" : ""}`} style={{ height: '100vh' }}>
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
  );
};

export default Loading;
