import React, { useEffect } from "react";
import useModuleState from "../../hooks/useModuleState";
/**
 * CardSelector Component
 *
 * A generic component for rendering a selection of cards that a user can choose from.
 * It's designed to be reusable for different types of selections such as tone, sentiment, audience, etc.
 *
 * Props:
 * - title (string): The title of the selection section.
 * - description (string): A brief description of what the user is selecting.
 * - options (array): An array of options where each option is an object containing `label`, `image`, and potentially other properties.
 * - selectedValue (string): The currently selected value.
 * - setSelectedValue (function): The function to call when a new value is selected.
 * - animationClass (string): Optional. A class to add animations or additional styling.
 */
const CardSelector = ({
  title,
  description,
  options,
  animationClass,
  styleName,
  module
}) => {
  const moduleState = useModuleState(module);
  const { articleState, setValue } = moduleState;
  // console.log(articleState)
  // Set the first option as selected on initial load
  useEffect(() => {
    if (options.length > 0 && !articleState[styleName]) {
      setValue(styleName, options[0].label);
    }
  }, [options, articleState, setValue, styleName]);

  return (
    <div className={`form-group form-section ${animationClass}`}>
      <h2>{title}</h2>
      <small className="form-text text-muted" style={{ paddingBottom: "20px" }}>
        {description}
      </small>
      <div className="row justify-content-center">
        {options.map((option, index) => (
          <div
            key={index}
            className="col-6 col-md-4 col-lg-3 mb-4 d-flex align-items-stretch"
          >
            <div
              className={`card uni-card ${
                articleState[styleName] === option.label  ? "selected" : ""
              }`}
              onClick={() => setValue(styleName, option.label)}
              style={{ cursor: "pointer", width: "100%" }}
            >
              <img
                src={option.image}
                className="card-img-top circular"
                alt={option.label}
                style={{ height: "150px", objectFit: "cover" }}
              />
              <div className="card-body">
                <h5 className="card-title" style={{ fontSize: ".8rem" }}>
                  {option.label}
                </h5>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardSelector;
