import React from "react";
import { useAuth0 } from '@auth0/auth0-react';



const HeroSection = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <header className="masthead">
      <section className="hero-section text-center text-black">
        <div className="hero-overlay"></div>{" "}
        {/* Optional overlay for better text visibility on background images */}
        <div className="container">
          <h1 className="hero-title">Welcome to 👻 Writr.</h1>
          <p className="hero-sub"> Effortless Writing, Hauntingly Good Content.</p>
          <a
            href="#signup"
            className="btn btn-primary btn-lg"
            onClick={() => loginWithRedirect({screen_hint: 'signup'})}
          >
            Start Writing Now
          </a>
        </div>
      </section>
    </header>
  );
};

export default HeroSection;
