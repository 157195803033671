import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";

const PreferencesContext = createContext();
const apiUrl = process.env.REACT_APP_API_URL;
const apiPort = process.env.REACT_APP_API_PORT;
const base_url = `${apiUrl}:${apiPort}`;

export const usePreferences = () => {
  const context = useContext(PreferencesContext);
  if (!context) {
    throw new Error("usePreferences must be used within a PreferencesProvider");
  }
  return context;
};

const useApi = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const fetchData = useCallback(
    async (url, options = {}) => {
      if (!isAuthenticated) return null;
  
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(`${base_url}${url}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            ...options.headers,
          },
          ...options,
        });
  
        if (!response.ok) {
          // Include the status code in the error for more granular error handling
          const error = new Error("Network response was not ok");
          error.status = response.status; // Add the HTTP status code to the error object
          throw error;
        }
  
        return await response.json();
      } catch (error) {
        console.error("Error fetching data:", error);
        throw error; // Rethrow the error to be handled by the caller
      }
    },
    [getAccessTokenSilently, isAuthenticated]
  );
  
  return { fetchData };
  
};

export const PreferencesProvider = ({ children }) => {
  const [profileDataContext, setProfileDataContext] = useState({
    userType: "",
    profile: {
      preferences: {},
    },
    subscriptions: {},
    payments: {},
  });

  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const { fetchData } = useApi();

  const [isProfileCreationActive, setIsProfileCreationActive] = useState(false);
  
  const triggerCreateProfileUI = () => {
    setIsProfileCreationActive(true); // Show the profile creation form
  };

  const loadUserProfile = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await fetchData("/api/v1/profile");
      if (!data) {
        // Handle the case where data is null or undefined, which might indicate no profile exists
        return; // Exit the function if no data is returned
      }
  
      setProfileDataContext(data); // Safely set the data now that we've checked it's not null
      if (data.profile) {
        // If there's a profile, set preferences and subscriptions
        const { profile, subscriptions } = data;
        setAppPreferences({ profile: { preferences: profile.preferences } });
        setAppSubscriptions({ subscriptions });
      }
      // console.log("isProfileCreationActive",isProfileCreationActive)
    }catch (error) {
      // console.log("er", error.status)
      if (error.status === 404) {
        // If the error status is 404, trigger profile creation UI
        triggerCreateProfileUI();
      } else {
        // Handle other types of errors
        console.error("Error loading user profile:", error);
      }
    } finally {
      setIsLoading(false); // Ensure loading state is reset in all cases
    }
  }, [fetchData]); // Add triggerCreateProfileUI to dependency array if it's defined outside of useCallback
  

  useEffect(() => {
    loadUserProfile();
  }, [loadUserProfile]);

  // Initial state setup for preferences and subscriptions if necessary
  const [appPreferences, setAppPreferences] = useState({
    profile: {
      preferences: {},
    },
  });
  const [appSubscriptions, setAppSubscriptions] = useState({
    subscriptions: [],
  });
  const [modules, setModules] = useState([]);

  // Function to update preferences both locally and in the backend
  const setDBAppPreferences = async (newPreferences) => {
    // Update local state first
    setAppPreferences((appPreferences) => ({
      ...appPreferences,
      tour: newPreferences, // Ensure newPreferences contains the tour property
    }));
    // Proceed with backend update only if 'tour' preference specifically changed
    if (appPreferences && appPreferences.tour !== newPreferences) {
      try {
        const token = await getAccessTokenSilently();
        await fetch(`${base_url}/api/v1/profile/update`, {
          method: "PATCH", // Using PATCH as we're partially updating the document
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            "profile.preferences.tour": newPreferences,
          }),
        });
        // Handle response or errors as necessary
        // Consider fetching the updated preferences again from the backend if needed
      } catch (error) {
        console.error("Error updating preferences:", error);
        // Handle the error appropriately
      }
    }
  };

  const [modulesActive, setModulesActive] = useState([]);
  const fetchModules = useCallback(async () => {
    setIsLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(`${base_url}/api/v1/admin/modules`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      if (!response.ok) throw new Error("Failed to fetch modules");
      const fetchedModules = await response.json();

      // Update modules state
      setModules(fetchedModules);

      // Calculate and update active modules
      const activeModules = fetchedModules.map((module) => ({
        ...module,
        active:
          appSubscriptions.subscriptions[0]?.modules?.includes(module.name) ||
          false,
      }));
      setModulesActive(activeModules);
    } catch (error) {
      console.error("Error fetching modules:", error);
    } finally {
      setIsLoading(false);
    }
  }, [getAccessTokenSilently, appSubscriptions]);

  useEffect(() => {
    fetchModules();
  }, [fetchModules]);

  return (
    <PreferencesContext.Provider
      value={{
        appPreferences,
        setDBAppPreferences,
        appSubscriptions,
        setAppSubscriptions,
        profileDataContext,
        modules,
        modulesActive,
        isLoading,
        isAuthenticated,
        setProfileDataContext,
        isProfileCreationActive,
        triggerCreateProfileUI,
        setIsProfileCreationActive
      }}
    >
      {children}
    </PreferencesContext.Provider>
  );
};
