import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { useAuth } from "./AuthContext";
const ArticleStateContext = createContext();

export const useArticleState = () => useContext(ArticleStateContext);

export const ArticleStateProvider = ({ children, showToast }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiPort = process.env.REACT_APP_API_PORT;
  const base_url = `${apiUrl}:${apiPort}/api/v1/articles`;

  const [articleState, setArticleState] = useState({
    topic: "",
    tone: "",
    sentiment: "",
    audience: "",
    sections: "3",
  });
  const [currentStep, setCurrentStep] = useState(1);
  const [articleSummary, setArticleSummary] = useState([]);
  const [updateArticle, setUpdateArticle] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stepsCompleted, setStepsCompleted] = useState(false);
  const copyTextareaRef = useRef(null);
  const { getAccessToken } = useAuth();
  const [accessToken, setaccessToken] = useState("");
  const [featureEnabled, setFeatureEnabled] = useState(true);
  const [docId, setDocId] = useState("");

  useEffect(() => {
    const getToken = async () => {
      const token = await getAccessToken();
      setaccessToken(token);
      // console.log(token);
    };
    getToken();
  }, [getAccessToken]); // Dependency array to ensure effect runs correctly

  const handleReset = (e) => {
    e.preventDefault();
    setValue("topic", "");
    setArticleSummary("");
    setUpdateArticle([]);
    setStepsCompleted(false);
    setCurrentStep(1);
    setDocId("");
  };

  // Generic setValue function
  const setValue = (name, value) => {
    // console.log("Before setting state:", name, value, articleState);
    setArticleState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // console.log("After setting state:", name, value, articleState);
  };

  // Added async function handleGenerateSummary within the StepProvider
  const handleGenerateSummary = async () => {
    const { topic, tone, sentiment, audience, sections } = articleState;
    setLoading(true);

    if (!accessToken) {
      setLoading(false);
      return; // Exit the function if there's no access token
    }

    try {
      const response = await fetch(`${base_url}/generateOutline`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ topic, tone, sentiment, audience, sections }),
      });
      const data = await response.json();

      // Example of handling response - adjust according to your API response
      const parts = data.response
        .split("#END#")
        .filter((section) => section.trim() !== "");
      const formattedResponse = parts.map((section, index) => ({
        id: index, // Unique identifier for the key prop
        content: section,
        // Your logic to handle content change
      }));

      setArticleSummary(formattedResponse);
      // console.log(formattedResponse)
      // setCurrentStep(currentStep + 1); // Proceed to the next step
      setStepsCompleted(true);
    } catch (error) {
      console.error("Error generating article summary:", error);
    } finally {
      setLoading(false);
    }
  };
  // Method to handle content changes from ResponseSummaryPart
  const handleContentChange = (index, newContent) => {
    setArticleSummary((prevResponse) =>
      prevResponse.map((section, idx) => {
        if (idx === index) {
          // Assuming each section object has an 'id' and 'content' field
          return { ...section, content: newContent };
        }
        return section;
      })
    );
  };

  const handleGenerateArticleSection = async (sectionContent, index) => {
    const { tone, sentiment, audience } = articleState;
    setLoading(true);
    // const accessToken = await getAccessToken(); // Retrieve the access token
    if (!accessToken) {
      setLoading(false);
      return; // Exit the function if there's no access token
    }
    try {
      const res = await fetch(`${base_url}/generateSection`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          section: sectionContent,
          index,
          tone,
          sentiment,
          audience,
        }),
      });
      const data = await res.json();
      setUpdateArticle((prevResponse) => [...prevResponse, data.response]); // Append new section
    } catch (error) {
      console.error("Error generating section:", error);
    } finally {
      setLoading(false);
      showToast("Article section generated.", "success");
    }
  };

  const handleAddConclusion = async () => {
    // Ensure there is content to work with
    if (updateArticle.length === 0) {
      showToast("Please generate content before adding a conclusion.", "warning");
      return; // Exit the function as there's nothing to generate a conclusion for
    }
  
    const { tone, character } = articleState;
    const articleContent = updateArticle.join("\n\n");
  
    try {
      setLoading(true); // Assuming you have a loading state
      if (!accessToken) {
        showToast("No access token available.", "error");
        setLoading(false);
        return; // Exit the function if there's no access token
      }
  
      const response = await fetch(`${base_url}/generateConclusion`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          content: articleContent,
          tone,
          character
        }),
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const data = await response.json();
      // Assuming the response contains a field 'conclusion' with the generated text
      setUpdateArticle((prevArticles) => [...prevArticles, data.response]);
      showToast("Article conclusion added.", "success");
    } catch (error) {
      console.error("Failed to generate conclusion:", error);
      showToast("There was a problem generating the conclusion.", "error");
    } finally {
      setLoading(false); // Reset loading state
    }
  };
  

  const checkRequired = () => {
    const { topic } = articleState;
    // console.log('REQUIRED', topic.trim());
    if (!topic.trim()) {
      showToast("Please enter a topic before proceeding.", "info"); // Show toast message
      return false;
    }
    return true; // Explicitly return true if the topic is valid
  };

  return (
    <ArticleStateContext.Provider
      value={{
        base_url,
        accessToken,
        loading,
        setLoading,
        articleState,
        setValue,
        currentStep,
        setCurrentStep,
        handleGenerateSummary,
        articleSummary,
        setArticleSummary,
        updateArticle,
        setUpdateArticle,
        handleGenerateArticleSection,
        checkRequired,
        handleContentChange,
        stepsCompleted,
        setStepsCompleted,
        copyTextareaRef,
        showToast,
        featureEnabled,
        setFeatureEnabled,
        handleReset,
        setArticleState,
        handleAddConclusion,
        docId, setDocId
      }}
    >
      {children}
    </ArticleStateContext.Provider>
  );
};
