import { useState } from "react";

export const useStoryState = () => {
  const [articleState, setArticleState] = useState({
    plot: "",
    tone: "",
    setting: "",
    character: "",
    chapters: "",
  });

  const toneOptions = [
    { label: "Light-hearted and Humorous", image: "/images/module/stories/light-hearted.png" },
    { label: "Dark and Mysterious", image: "/images/module/stories/dark-mysterious.png" },
    { label: "Romantic and Sentimental", image: "/images/module/stories/romantic-sentimental.png" },
    { label: "Adventurous and Thrilling", image: "/images/module/stories/adventurous-thrilling.png" },
    { label: "Reflective and Philosophical", image: "/images/module/stories/reflective-philosophical.png" },
];

const settingOptions = [
    { label: "Futuristic City", image: "/images/module/stories/futuristic-city.png" },
    { label: "Medieval Kingdom", image: "/images/module/stories/medieval-kingdom.png" },
    { label: "Small Town Modern Day", image: "/images/module/stories/small-town-modern.png" },
    { label: "Distant Planet", image: "/images/module/stories/distant-planet.png" },
    { label: "Historical Era", image: "/images/module/stories/historical-era.png" },
];

const characterOptions = [
    { label: "Flawed Hero", image: "/images/module/stories/flawed-hero.png" },
    { label: "Villain with a Heart of Gold", image: "/images/module/stories/villain-heart-gold.png" },
    { label: "Outsider Looking In", image: "/images/module/stories/outsider-looking-in.png" },
    { label: "Wise Mentor", image: "/images/module/stories/wise-mentor.png" },
    { label: "Reluctant Hero", image: "/images/module/stories/reluctant-hero.png" },
];

const chapterStructureOptions = [
    { label: "Linear Progression", image: "/images/module/stories/linear-progression.png" },
    { label: "Non-linear (Flashbacks)", image: "/images/module/stories/non-linear-flashbacks.png" },
    { label: "Parallel Narratives", image: "/images/module/stories/parallel-narratives.png" },
    { label: "Episodic Chapters", image: "/images/module/stories/episodic-chapters.png" },
    { label: "Single Day", image: "/images/module/stories/single-day.png" },
];

  // Other states managed by this hook

  // Return all states and setters managed by this hook

  return {
    toneOptions,
    settingOptions,
    characterOptions,
    chapterStructureOptions,
    articleState,
    setArticleState,
    // setValue
  };
};
