// import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is imported

function TourToggle({isToggled, onToggle}) {

  return (
    <button 
      type="button" 
      className={`btn ${isToggled ? 'btn-primary' : 'btn-secondary'}`} 
      onClick={onToggle}
    >
      {isToggled ? 'Tour On' : ' Tour Off'}
    </button>
  );
}

export default TourToggle;