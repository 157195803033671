import React from 'react';
import ArticleSectionPart from './ArticleSectionPart';
import useModuleState from "../hooks/useModuleState";

const ArticleBody = ({ module }) => {
  const moduleState = useModuleState(module);

  const {
    updateArticle,
    accessToken,
    setLoading,
    setUpdateArticle,
    showToast,
    base_url,
    articleSummary,
  } = moduleState;

  const handleRegenerateArticleSection = async (index) => {
    setLoading(true);
    if (!accessToken) {
      setLoading(false);
      return; // Exit the function if there's no access token
    }
    try {
      // console.log(index, articleSummary[index].content)
      const res = await fetch(`${base_url}/generateSection`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          section: articleSummary[index].content,
          index,
        }),
      });
      const data = await res.json();
      const updatedResponse = [...updateArticle];
      updatedResponse[index] = data.response;
      setUpdateArticle(updatedResponse);
    } catch (error) {
      console.error("Error regenerating section:", error);
    } finally {
      setLoading(false);
      showToast("Article section regenerated.", "success");
    }
  };

  const handleDeleteSection = (indexToDelete) => {
    setUpdateArticle((currentSections) =>
      currentSections.filter((_, index) => index !== indexToDelete)
    );
    showToast("Article section deleted.", "danger");
  };

  const moveSectionUp = (index) => {
    setUpdateArticle((currentArticles) => {
      if (index === 0) return currentArticles; // Can't move up the first item
      const newArticles = [...currentArticles];
      [newArticles[index], newArticles[index - 1]] = [
        newArticles[index - 1],
        newArticles[index],
      ]; // Swap
      return newArticles;
    });
  };

  const moveSectionDown = (index) => {
    setUpdateArticle((currentArticles) => {
      if (index === currentArticles.length - 1) return currentArticles; // Can't move down the last item
      const newArticles = [...currentArticles];
      [newArticles[index], newArticles[index + 1]] = [
        newArticles[index + 1],
        newArticles[index],
      ]; // Swap
      return newArticles;
    });
  };
  // console.log(updateArticle)
  return (
    
    <div className="article-body">
      {updateArticle.map((section, index) => (
        <ArticleSectionPart
          key={index}
          content={section}
          handleRegenerateArticleSection={() => handleRegenerateArticleSection(index)}
          handleDeleteSection={() => handleDeleteSection(index)}
          moveSectionUp={() => moveSectionUp(index)}
          moveSectionDown={() => moveSectionDown(index)}
          index={index}
          totalSections={updateArticle.length}
          isConclusion={
            index === updateArticle.length - 1 &&
            section.toLowerCase().includes("conclusion")
          }
        />
      ))}
    </div>
  );
};

export default ArticleBody;
