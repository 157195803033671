import React, { createContext, useContext, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react"; /**

 * AuthContext.js
 * This file defines the Context and Provider for managing authentication state across the application. 
 * The AuthContext allows components to access and update authentication-related information, such as 
 * the user's logged-in status, user profile information, and authentication tokens, in a centralized manner 
 * without prop drilling. This global state management for authentication data simplifies the implementation 
 * of access control and user-specific features throughout the application.
 *
 * The AuthProvider component encapsulates the authentication logic, including initializing the authentication 
 * state, handling sign-in and sign-out flows, and refreshing authentication tokens as necessary. By wrapping 
 * the application or relevant parts of it with the AuthProvider, all descendant components gain access to the 
 * authentication context and can react to changes in the authentication state.
 *
 * This context and provider setup is crucial for building secure and user-friendly applications that require 
 * user authentication, offering a consistent and accessible interface for managing authentication state.
 *
 * Usage:
 * Wrap your component tree with the AuthProvider to provide access to the authentication context:
 * <AuthProvider>
 *   <App />
 * </AuthProvider>
 *
 * To access the authentication context in a component:
 * const { isAuthenticated, user, login, logout } = useContext(AuthContext);
 *
 * This setup facilitates secure and efficient authentication state management, enhancing the application's 
 * security and user experience by providing a unified method for accessing and manipulating user authentication data.
 */

// Create the context
const AuthContext = createContext({
  isAuthenticated: false, // Default values
  user: null, // Adjust according to your context structure
  login: () => {},
  logout: () => {},
});

// Define a provider component
export const AuthProvider = ({ children }) => {
  // const [setIsAuthenticated] = useState(false);
  // const [user, setUser] = useState(null);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  useEffect(() => {
    // Placeholder for initialization logic, e.g., checking local storage or cookies for existing authentication tokens
    // Initialize or clean up authentication state as needed
    if (isAuthenticated) {
      // Optionally, check for a specific destination in local storage or state
      // If exists, navigate to that destination, else stay on /modules
      // Example: const destination = localStorage.getItem('destination') || '/modules';
      // navigate(destination);
    }
  }, [isAuthenticated]);

  // const login = (userData) => {
  //   // Placeholder for login logic
  //   setIsAuthenticated(true);
  //   setUser(userData);
  //   // Possibly set tokens in local storage or cookies here
  // };

  const getAccessToken = async () => {
    if (!isAuthenticated) {
      console.log("User is not authenticated.");
      return;
    }
    try {
      const token = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTHO_IDENTIFIER, // Replace with your API's audience value
        scope: "read:current_user",
      });
      // console.log("Token:", token);
      return token;
    } catch (error) {
      console.error("Error getting access token", error);
      // showToast("Failed to get access token.", "danger");
      return null;
    }
  };

  // const logout = () => {
  //   // Placeholder for logout logic
  //   setIsAuthenticated(false);
  //   setUser(null);
  //   // Clean up tokens from local storage or cookies here
  // };

  // The value prop defines what data and methods are accessible to consuming components
  const value = { isAuthenticated, getAccessToken };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

// Custom hook to use the auth context
export const useAuth = () => useContext(AuthContext);

export default AuthContext;
