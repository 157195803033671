import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { ArticleStateProvider } from "./context/ArticleStateContext";
import ReactGA from "react-ga4";
import { AuthProvider } from "./context/AuthContext"; // Adjust the path as necessary
import LeftMenu from "./components/LeftMenu";
import Footer from "./components/Footer";
import Toast from "./components/Toast";
import { StepProvider } from "./context/StepContext";
import StepManager from "./components/StepManager";
import Loading from "./components/Loading";
import { useArticleState } from "./hooks/useArticleState";
import { stepsConfig } from "./ArticlesConfig";
import "./ArticleModule.css";

const Articles = ({ config }) => {
  const [isToastVisible, setToastVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastColour, setToastColour] = useState("");
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate(); // Use useNavigate hook
  const { _id } = useParams(); // If using React Router v6
  const [moduleData, setModuleData] = useState(null);
  const [loading, setLoading] = useState(false);

  // MODULE CONFIG
  const moduleTitle = "Articles.";
  const module = "articles";
  // Use the custom hook to manage state.
  const {
    topic,
    setTopic,
    tone,
    setTone,
    toneOptions,
    sentiment,
    setSentiment,
    sentimentOptions,
    audience,
    setAudience,
    audienceOptions,
    sections,
    setSections,
  } = useArticleState();

  // Assuming getStepsConfig is updated to utilize the additional state
  const steps = stepsConfig({
    topic,
    setTopic,
    tone,
    setTone,
    toneOptions,
    sentiment,
    setSentiment,
    sentimentOptions,
    audience,
    setAudience,
    audienceOptions,
    sections,
    setSections,
  });

  const getModuleDataById = useCallback(async (_id, accessToken) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const apiPort = process.env.REACT_APP_API_PORT;
    const base_url = `${apiUrl}:${apiPort}`;
    try {
      // Ensure the `accessToken` parameter is passed to this function
      // Replace `YOUR_API_ENDPOINT` with the actual base URL of your API
      // and ensure the endpoint matches your backend's route for fetching module data by ID

      const response = await fetch(`${base_url}/api/v1/content/${_id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`, // Include the Bearer token in the Authorization header
        },
      });

      if (!response.ok) {
        // If the server response is not ok, throw an error
        throw new Error(`Error: ${response.status}`);
      }

      // Assuming the response is in JSON format
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Failed to fetch module data", error);
      // Depending on how you want to handle errors, you might want to re-throw the error
      // or return null/undefined to indicate the fetch operation failed
      throw error;
    }
  }, []); // Add dependencies if the function depends on any props or state
  
  useEffect(() => {
    const fetchData = async () => {
      if (!isAuthenticated ) {
        console.log("Authentication is required to manage articles.");
        navigate("/login");
        return;
      }

      try {
        setLoading(true); // Assuming setLoading doesn't change, no need in deps
        const accessToken = await getAccessTokenSilently();
        const data = await getModuleDataById(_id, accessToken);
        setModuleData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (_id) {
      fetchData();
    } // Removed getModuleDataById and setLoading from dependencies as explained
  }, [
    _id,
    isAuthenticated,
    getAccessTokenSilently,
    navigate,
    getModuleDataById,
    setLoading
  ]);

  // Function to show toast
  const showToast = (message, colour) => {
    setToastMessage(message);
    setToastColour(colour);
    // console.log(colour)
    setToastVisible(true);
    setTimeout(() => setToastVisible(false), 3000); // Hide after 3 seconds
    ReactGA.event({
      category: "User",
      action: message,
      label: "Button Click",
    });
  };
  // Function to close toast
  const closeToast = () => {
    setToastVisible(false);
  };

  return (
    <AuthProvider>
      <ArticleStateProvider showToast={showToast}>
        <div className="container-fluid overflow-hidden module">
          {" "}
          {/* Change to container-fluid for full width */}
          <Loading module={module} loading={loading} />
          <div className="row vh-100 overflow-auto">
            <LeftMenu module={module} />

            <div className="col d-flex flex-column h-sm-100">
              <main className="row overflow-auto">
                <div className="col pt-4">
                  {/* <NavBar module={module} /> */}
                  <h1 className="moduleHeader">{moduleTitle}</h1>
                  <form>
                    <StepProvider module={module}>
                      <div className="App">
                        <StepManager
                          steps={steps}
                          module={module}
                          data={moduleData}
                        />
                      </div>
                    </StepProvider>
                  </form>
                </div>
              </main>
              <Footer />
            </div>
            <Toast
              message={toastMessage}
              colour={toastColour}
              isVisible={isToastVisible}
              onClose={closeToast}
            />
          </div>
        </div>
      </ArticleStateProvider>
    </AuthProvider>
  );
};

export default Articles;
