import React from "react";
import { usePreferences } from "../../context/PreferencesContext";

function ModuleButtons({ isToggled, onToggle }) {
  const { modulesActive } = usePreferences();
  

  const loadModule = (name) => {
    // This will cause a full page reload to the specified path
    window.location.href = `/${name}`;
    // console.log(name);
  };

  return (
    <div className="d-grid gap-2">
      <hr />
      <h5>Modules:</h5>
      {modulesActive.map(
        (option, index) =>
          option.active && (
            <button
              key={index}
              type="button"
              className="btn btn-primary"
              onClick={() => loadModule(option.route)}
            >
              {option.header}
            </button>
          )
      )}
    </div>
  );
}

export default ModuleButtons;
