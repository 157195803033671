import React, { useState, useEffect } from "react";
import Markdown from "react-markdown";
import useModuleState from "../hooks/useModuleState";
import { usePreferences } from "../../context/PreferencesContext";
import { getTour } from "../utils/driverInstance";

function ArticleSummary({ index, module }) {
  const moduleState = useModuleState(module);

  const {
    articleSummary,
    accessToken,
    setLoading,
    setUpdateArticle,
    showToast,
    base_url,
    currentStep
  } = moduleState;

  const initialContent = articleSummary[index]?.content || ""; // Ensure safe access to properties
  const [editableContent, setEditableContent] = useState(initialContent);
  const [showPreview, setShowPreview] = useState(true);
  const { appPreferences } = usePreferences();


  useEffect(() => {
    if (appPreferences.tour) {
      // console.log("yes")
      getTour(1).drive();
    }
    // It might be necessary to reset animationClass to empty or a default state after the animation completes
    // to prevent old animations from affecting new transitions.
  }, [currentStep,  appPreferences.tour]);

  // Simplify handleChange - no need for async or preventDefault here since it's just updating local state
  const handleChange = (e) => {
    const newContent = e.target.value;
    setEditableContent(newContent);
  };

  // No need for async, preventDefault, or stopPropagation for togglePreview
  const togglePreview = () => {
    setShowPreview(!showPreview);
  };

  // Use proper parameters - looks like the function is meant to be called without parameters
  const handleGenerateClick = () => {
    // console.log(index);

    // Call the generation function with proper parameters
    handleGenerateArticleSection(editableContent, index); // Assuming tone, sentiment, audience are managed elsewhere or not needed
  };

  // Ensure handleGenerateArticleSection function is defined correctly elsewhere
  // This function should ideally be passed down as a prop or accessed through context if it involves API calls

  const handleGenerateArticleSection = async (
    sectionContent,
    index,
    tone,
    sentiment,
    audience
  ) => {
    setLoading(true);
    // const accessToken = await getAccessToken(); // Retrieve the access token
    if (!accessToken) {
      setLoading(false);
      console.log("no token");
      return; // Exit the function if there's no access token
    }
    try {
      const res = await fetch(`${base_url}/generateSection`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          section: sectionContent,
          index,
          tone,
          sentiment,
          audience,
        }),
      });
      const data = await res.json();
      setUpdateArticle((prevResponse) => [...prevResponse, data.response]); // Append new section
    } catch (error) {
      console.error("Error generating section:", error);
    } finally {
      setLoading(false);
      showToast("Article section generated.", "success");
    }
  };

  return (
    <div className="articleSummary-section" id="tour-step-2">
      {showPreview ? (
        <Markdown>{editableContent}</Markdown>
      ) : (
        <textarea
          className="form-control"
          rows="5"
          value={editableContent}
          onChange={handleChange}
        />
      )}
      <div
        className="btn-group"
        role="group"
        aria-label="Article Contents Section"
        id="tour-step-3"
      >
        <button
          type="button"
          className="btn btn-primary"
          id="tour-step-4"
          onClick={handleGenerateClick}
          disabled={!showPreview} // Disable the button when in preview mode
        >
          Generate
        </button>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={togglePreview}
          id="tour-step-5"
        >
          {showPreview ? "Edit" : "Save"}
        </button>
      </div>
      
    </div>
  );
}

export default ArticleSummary;
