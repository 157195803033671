import React, { useRef, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LeftMenu from "./components/LeftMenu";
import Loading from "./Loading";
import Footer from "./components/Footer";
import ContentTable from "./components/ContentTable";
import {usePreferences} from "./context/PreferencesContext"
import "./Profile.css"

const apiUrl = process.env.REACT_APP_API_URL;
const apiPort = process.env.REACT_APP_API_PORT;
const base_url = `${apiUrl}:${apiPort}`;

const Profile = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { profileDataContext, modulesActive } = usePreferences();
  const [profileData, setProfileData] = useState({
    _id: "",
    auth0Id:"",
    email: "",
    userType: "",
    profile: {
      avatarUrl: "",
      firstName: "",
      lastName: "",
      preferences: {
        newsletter: false,
        tour: true,
        language: "",
        categories: [],
      },
    },
    subscriptions: {
      type: "",
      status: "",
      startDate: "",
      endDate: "",
    },
    payments: {
      subscriptionType: "",
      status: "",
    },
  });

  const [profileContent, setProfileContent] = useState();
  const [deleteSuccess, setDeleteSuccess] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Assuming other state and useEffect setup remains as before
  const fileInputRef = useRef(null);

  // Function to trigger the hidden file input click
  const handleOverlayClick = () => {
    if (isEditing) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      // const getUserProfile = async () => {
      //   setIsLoading(true); // Start loading
      //   try {
      //     const accessToken = await getAccessTokenSilently();
      //     const response = await fetch(`${base_url}/api/v1/profile`, {
      //       headers: {
      //         Authorization: `Bearer ${accessToken}`,
      //       },
      //     });

      //     if (!response.ok) {
      //       throw new Error("Network response was not ok");
      //     }

      //     const data = await response.json();
      //     setProfileData(
      //       data || {
      //         email: "",
      //         userType: "",
      //         profile: {
      //           avatarUrl: "",
      //           firstName: "",
      //           lastName: "",
      //           preferences: {
      //             newsletter: false,
      //             tour: true,
      //             language: "",
      //             categories: [],
      //           },
      //         },
      //         subscriptions: {
      //           type: "",
      //           status: "",
      //           startDate: "",
      //           endDate: "",
      //         },
      //         payments: {
      //           subscriptionType: "",
      //           status: "",
      //         },
      //         // Provide defaults for other fields as necessary
      //       }
      //     );
      //   } catch (error) {
      //     console.error(error);
      //   } finally {
      //     setIsLoading(false); // Stop loading
      //   }
      // };
      //getUserProfile();
      setProfileData(profileDataContext);

      const getUserContent = async () => {
        setIsLoading(true); // Start loading
        try {
          const accessToken = await getAccessTokenSilently();
          const response = await fetch(`${base_url}/api/v1/content`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          if (!response.ok) {
            console.error(
              "Failed to fetch user content: Network response was not ok."
            );
            setProfileContent([]); // Set profile content to an empty array in case of failure
            return; // Early return to avoid further processing
          }

          let data = await response.json();

          if (!data || data.length === 0) {
            // Check if the data is empty or not present
            setProfileContent([]);
            console.log("No content found.");
            return; // Early return to avoid sorting and processing empty data
          }

          // Sort the data by createdDate, newest first
          data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

          // Process the sorted data
          const processedData = data.map((item) => ({
            ...item, // Spread the rest of the item's properties
            summaryCount: item.summary?.length || 0, // Count of summaries, defaulting to 0
            bodyCount: item.body?.length || 0, // Count of body items, defaulting to 0
          }));

          // Update state with processed data
          setProfileContent(processedData);
        } catch (error) {
          console.error("Error fetching user content:", error);
          setProfileContent([]); // Ensure to set profile content to an empty array in case of error
        } finally {
          setIsLoading(false); // Stop loading
        }
      };

      getUserContent();
      // console.log(profileContent);
    }
  }, [isAuthenticated, getAccessTokenSilently, deleteSuccess, profileDataContext]);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "ml_default");

    try {
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/dkohl0qz7/image/upload`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) throw new Error("Failed to upload image");

      const data = await response.json();
      // console.log("IMGURL:", data.secure_url);
      console.log("IMGURL:", data.secure_url);
      setProfileData((prevState) => ({
        ...prevState,
        profile: {
          ...prevState.profile,
          avatarUrl: data.secure_url, // Correctly update avatarUrl inside the nested profile object
        },
      }));

      // console.log(profileData);
    } catch (error) {
      console.error("Error uploading image: ", error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    // Determining if the input is directly on the profileData
    if (name in profileData) {
      setProfileData({ ...profileData, [name]: value });
    } else if (name in profileData.profile) {
      // Updating properties within profile
      setProfileData({
        ...profileData,
        profile: { ...profileData.profile, [name]: value },
      });
    } else if (name in profileData.profile.preferences) {
      // Updating properties within profile.preferences
      setProfileData({
        ...profileData,
        profile: {
          ...profileData.profile,
          preferences: {
            ...profileData.profile.preferences,
            [name]: type === "checkbox" ? checked : value,
          },
        },
      });
    } else {
      console.warn("Unhandled form field:", name);
    }
  };

  const handleSaveProfile = async (event) => {
    event.preventDefault(); // Prevent the form from submitting the traditional way
    // console.log(profileData);

    try {
      const accessToken = await getAccessTokenSilently();

      const response = await fetch(`${base_url}/api/v1/profile/update`, {
        method: "PUT", // or PATCH if partial updates are supported
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(profileData),
      });

      if (!response.ok) {
        throw new Error("Failed to update profile");
      }

      console.log("Profile updated successfully!");
      setIsEditing(false); // Assuming you have an `isEditing` state to toggle edit mode
    } catch (error) {
      console.error("Error saving profile: ", error);
    }
  };

  const handleDelete = async (_id) => {
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(`${base_url}/api/v1/content/${_id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) throw new Error("Failed to delete content");
      console.log("Content deleted successfully");
      setDeleteSuccess(true); // Update deleteSuccess upon successful deletion
    } catch (error) {
      console.error("Error deleting content:", error);
      setDeleteSuccess(false); // Optionally reset on error or handle differently
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="container-fluid overflow-hidden module">
      <div className="row vh-100 overflow-auto">
        <LeftMenu module={module} modulesActive={modulesActive} />
        <div className="col d-flex flex-column h-sm-100">
          <main className="row overflow-auto">
            <div className="col pt-4">
              <h1 className="moduleHeader">Your Profile</h1>
              <form onSubmit={(event) => handleSaveProfile(event)}>
                <div className="container">
                  {/* Row for First Name, Last Name, and Email */}
                  <div className="row justify-content-center">
                    <div className="col-md-6">
                      <div className="p-3 border bg-light">
                        <label className="labels">First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="first name"
                          value={profileData.profile.firstName}
                          onChange={handleInputChange}
                          name="firstName"
                          disabled={!isEditing}
                        />
                        <label className="labels">Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="last name"
                          value={profileData.profile.lastName}
                          onChange={handleInputChange}
                          name="lastName"
                          disabled={!isEditing}
                        />
                        <label className="labels">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="email"
                          value={profileData.email}
                          onChange={handleInputChange}
                          name="email"
                          disabled={!isEditing}
                        />
                      </div>
                    </div>

                    {/* Column for Profile Image */}
                    <div className="col-md-6">
                      <div
                        className="p-3 border bg-light text-center position-relative"
                      >
                        <div
                          className="rounded-circle mb-3"
                          style={{
                            position: "relative",
                            display: "inline-block", // This makes the container as large as its contents
                          }}
                          onClick={handleOverlayClick}
                        >
                          <img
                            src={profileData.profile.avatarUrl || user.picture}
                            alt="Profile"
                            width="150"
                            style={{
                              display: "block", // This ensures the image does not have extra space below it
                              borderRadius: "50%", // Ensure the image is round if it isn't already
                            }}
                          />
                          {isEditing && (
                            <div
                              className="overlay"
                              style={{
                                position: "absolute",
                                top: "0", // Adjust as needed
                                left: "0", // Adjust as needed
                                width: "100%", // This will cover the image width
                                height: "100%", // This will cover the image height
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                color: "white",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "50%", // Match the image's borderRadius to keep the round shape
                                cursor: "pointer",
                                fontSize: "2em"
                              }}
                            >
                              <i className="bi bi-cloud-arrow-up"></i>
                            </div>
                          )}
                        </div>
                        <input
                          type="file"
                          ref={fileInputRef}
                          onChange={handleImageUpload}
                          style={{ display: "none" }}
                        />
                        <div>
                          {profileData.subscriptions?.[0]?.type ||
                            "Default Type"}{" "}
                          user - {profileData.subscriptions?.[0]?.status || ""}
                        </div>{" "}
                        {/* Row for Edit/Save Button */}
                        <div className="editProfile">
                            {isEditing ? (
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary profile-button"
                                >
                                  <i className="bi bi-save"></i>
                                </button>
                                <button
                                  type="button"
                                  onClick={() => setIsEditing(false)}
                                  className="btn btn-secondary"
                                >
                                  <i className="bi bi-x-square"></i>
                                </button>
                              </div>
                            ) : (
                              <button
                                type="button"
                                onClick={() => setIsEditing(true)}
                                className="btn btn-secondary"
                              >
                                <i className="bi bi-pencil-square"></i>
                              </button>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Row for Language and Newsletter Subscription */}
                  {/* <div className="row justify-content-center mt-4">
                    <div className="col-md-12">
                      <div className="p-3 border bg-light">
                        <label className="labels">Language</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="language"
                          value={
                            profileData?.profile?.preferences?.language ?? ""
                          }
                          onChange={(e) =>
                            setProfileData({
                              ...profileData,
                              profile: {
                                ...profileData.profile,
                                preferences: {
                                  ...profileData.profile.preferences,
                                  language: e.target.value,
                                },
                              },
                            })
                          }
                          name="language"
                          disabled={!isEditing}
                        />
                        <label className="labels">
                          Newsletter Subscription
                        </label>
                        <select
                          className="form-control"
                          value={
                            profileData?.profile?.preferences?.newsletter
                              ? "Yes"
                              : "No"
                          }
                          onChange={(e) =>
                            setProfileData({
                              ...profileData,
                              profile: {
                                ...profileData.profile,
                                preferences: {
                                  ...profileData.profile.preferences,
                                  newsletter: e.target.value === "Yes",
                                },
                              },
                            })
                          }
                          name="newsletter"
                          disabled={!isEditing}
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                        <label className="labels">Interactive Tour</label>
                        <select
                          className="form-control"
                          value={
                            profileData?.profile?.preferences?.tour
                              ? "On"
                              : "Off"
                          }
                          onChange={(e) =>
                            setProfileData({
                              ...profileData,
                              profile: {
                                ...profileData.profile,
                                preferences: {
                                  ...profileData.profile.preferences,
                                  tour: e.target.value === "Yes",
                                },
                              },
                            })
                          }
                          name="tour"
                          disabled={!isEditing}
                        >
                          <option value="Yes">On</option>
                          <option value="No">Off</option>
                        </select>
                      </div>
                    </div>
                  </div> */}
                </div>
                {!isLoading && profileContent ? (
                <ContentTable
                  data={profileContent}
                  handleDelete={handleDelete}
                />
              ) : (
                " "
              )}
              </form>
              
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Profile;
