import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
// Import Auth0 context or any method to retrieve user info from Auth0

const CreateUserProfileForm = ({ onSave }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState(user?.email || "");
  const auth0Id = user?.sub; // This is the Auth0 user ID
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiPort = process.env.REACT_APP_API_PORT;
  const base_url = `${apiUrl}:${apiPort}`;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${base_url}/api/v1/admin/createProfile`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`, // Correct placement
        },
        body: JSON.stringify({
          auth0Id,
          email_address: email,
          merge_fields: { FNAME: firstName, LNAME: lastName },
        }),
      });
      if (!response.ok) {
        throw new Error("Failed to create user profile");
      }
      const data = await response.json();
      onSave(data); // Assuming there's a callback function to handle the saved profile
    } catch (error) {
      console.error("Error creating user profile:", error);
    }
  };  

  return (
    <div className="container">
      <div className="container px-2 py-3">
        <h1 className="display-4 fw-bold lh-1 text-body-emphasis moduleHeader">
          Welcome to 👻 Writr modules!
        </h1>
        <p>
          In order to being we need to initiate you profile. Simply fill in the
          data below start.
        </p>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="firstNameInput" className="form-label">
              First Name:
            </label>
            <input
              type="text"
              className="form-control"
              id="firstNameInput"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="lastNameInput" className="form-label">
              Last Name:
            </label>
            <input
              type="text"
              className="form-control"
              id="lastNameInput"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="emailInput" className="form-label">
              Email:
            </label>
            <input
              type="email"
              className="form-control"
              id="emailInput"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Create Profile
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateUserProfileForm;
