import React from 'react';

const UsersTable = ({ users, onEdit }) => {

  const handleDeleteUser = async (userId) => {
    console.log(`Delete user ${userId}`);
    // Implement the delete user logic here
  };

  return (
    <table className="table table-striped table-bordered table-hover table-sm">
      <thead>
        <tr>
          <th>#</th>
          <th>Email</th>
          <th>User Type</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user, index) => (
          <tr key={user._id || index}>
            <td>{index + 1}</td>
            <td>{user.email}</td>
            <td>{user.userType}</td>
            <td>{user.profile.firstName}</td>
            <td>{user.profile.lastName}</td>
            <td>
              <button className="btn btn-outline-primary btn-sm" onClick={() => onEdit(user)}><i className="bi bi-pencil-square"></i></button>{' '}
              <button className="btn btn-outline-danger btn-sm" onClick={() => handleDeleteUser(user._id)}><i className="bi bi-trash"></i></button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default UsersTable;
