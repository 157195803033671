import React, { useEffect, useState } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import "./Admin.css"; // Ensure the path is correct based on your file structure
import { usePreferences } from "../context/PreferencesContext";

const AdminDashboard = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { profileDataContext } = usePreferences();
  const { userType } = profileDataContext;
  const navigate = useNavigate();

  useEffect(() => {
    // This useEffect will run every time userType changes.
    // Ensure that the initial value of userType allows to distinguish between not loaded and loaded states.
    if (userType === "") {
      // Data not yet loaded, you can optionally handle a loading state here.
      console.log("User type is still loading...");
    } else if (userType !== "admin") {
      // Once userType is loaded and confirmed not to be 'admin', perform the redirect.
      console.log("Not an admin, redirecting...");
      navigate("/");
    } else {
      console.log("Admin Loaded")
    }
  }, [userType, navigate]);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div
      className={`d-flex ${isCollapsed ? "toggled" : ""}`}
      id="admin-wrapper"
    >
      {/* Sidebar */}
      <div className="bg-light border-right" id="sidebar-wrapper">
        <div className="sidebar-heading">
          {" "}
          <Link
            to="/admin"
            className="list-group-item list-group-item-action bg-light"
          >
            Admin Dashboard
          </Link>
        </div>
        <div className="list-group list-group-flush">
          <Link
            to="/admin/users"
            className="list-group-item list-group-item-action bg-light"
          >
            <i className="bi bi-person-badge me-2"></i>
            Users
          </Link>
          <Link
            to="/admin/products"
            className="list-group-item list-group-item-action bg-light"
          >
            <i className="bi bi-pencil me-2"></i>
            Products
          </Link>
          <Link
            to="/admin/config"
            className="list-group-item list-group-item-action bg-light"
          >
            <i className="bi bi-gear me-2"></i>
            Settings
          </Link>
          <Link
            to="/profile"
            className="list-group-item list-group-item-action bg-light"
          >
            <i className="bi bi-person me-2"></i>
            Profile
          </Link>
        </div>
      </div>
      {/* /#sidebar-wrapper */}

      {/* Page Content */}
      <div id="page-content-wrapper">
        <nav className="navbar navbar-expand-lg">
          <button className="btn" id="menu-toggle" onClick={toggleSidebar}>
            <i className="bi bi-list"></i>
          </button>
        </nav>
        <div className="container-fluid">
          <h1 className="mt-4">Admin Dashboard</h1> <hr />
          {/* Route-specific content will be rendered here */}
          <Outlet />
        </div>
      </div>
      {/* /#page-content-wrapper */}
    </div>
  );
};

export default AdminDashboard;
