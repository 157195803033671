import { useState } from "react";

export const useArticleState = () => {
  const [articleState, setArticleState] = useState({
    topic: "",
    tone: "",
    sentiment: "",
    audience: "",
    sections: "",
  });
 
  const toneOptions = [
    { label: "Harvard Business Review", image: "/images/module/articles/hbr-image.png" },
    { label: "The New York Times", image: "/images/module/articles/nytimes-image.png" },
    { label: "The Guardian", image: "/images/module/articles/guardian-image.png" },
    { label: "The Economist", image: "/images/module/articles/economist-image.png" },
  ];

  const sentimentOptions = [
    { label: "I want to inform", image: "/images/module/articles/inform-image.png" },
    { label: "I want to help", image: "/images/module/articles/help-image.png" },
    { label: "I want to share", image: "/images/module/articles/share-image.png" },
  ];

  const audienceOptions = [
    { label: "Professionals", image: "/images/module/articles/professionals-image.png" },
    { label: "Job Seekers", image: "/images/module/articles/job-seekers-image.png" },
    { label: "Generalists", image: "/images/module/articles/generalists-image.png" },
  ];
  // Other states managed by this hook
  const [sections, setSections] = useState(3); // Default to 3 sections

  // Return all states and setters managed by this hook

  return {
    toneOptions,
    sentimentOptions,
    audienceOptions,
    sections,
    setSections,
    articleState,
    setArticleState,
    // setValue
  };
};
