import React from 'react';

const StepNavigation = ({ currentStep, setCurrentStep, totalSteps, checkRequired, prevStep, handleGenerateSummary }) => {
  return (
    <div className="btn-group" role="group" aria-label="Form navigation buttons" style={{ display: "flex", justifyContent: "flex-end" }}>
      {currentStep > 1 && currentStep <= totalSteps && (
        <button type="button" className="btn btn-secondary" onClick={() => setCurrentStep(currentStep - 1)}>
          Back
        </button>
      )}
      {currentStep < totalSteps && (
        <button
          type="button"
          className="btn btn-primary"
          onClick={async () => {
            const isRequiredMet = await checkRequired();
            if (isRequiredMet) {
              setCurrentStep(currentStep + 1);
            }
          }}
        >
          Next
        </button>
      )}
      {currentStep === totalSteps && (
        <button type="button" className="btn btn-success" onClick={handleGenerateSummary}>
          Generate Contents
        </button>
      )}
    </div>
  );
};

export default StepNavigation;
