import React from "react";
// import "bootstrap-icons";

const FeaturesSection = () => {
  const features = [
    {
      header: "Laser-Sharp Focus",
      desc: "Ghost Writr's distraction-free interface sharpens your concentration, turning your workspace into a haven of productivity where thoughts become text effortlessly.",
      icon: "bi bi-compass m-auto text-primary",
    },
    {
      header: "Seamless Flow",
      desc: "With Ghost Writr, structure your narratives smoothly using intuitive tools that ensure every idea connects coherently, from inception to conclusion.",
      icon: "bi bi-scooter m-auto text-primary",
    },
    {
      header: "Editing Suite",
      desc: "Elevate your drafts with Ghost Writr's editing suite, where intelligent suggestions enhance style, tone, and clarity, making your voice resonate more powerfully.",
      icon: "bi bi-columns m-auto text-primary",
    },
    {
      header: "Export Tools",
      desc: "Easily export your article so that you can directly publish to popular platforms, streamlining the process from creation to distribution.",
      icon: "bi bi-upload m-auto text-primary",
    },
  ];

  const iconStyles = {
    fontSize: "4rem",
    color: "cornflowerblue",
  };

  return (
    <section className="features-section">
      <div className="container">
        <div className="row">
          {/* Column for feature items */}
          <div className="col-md-6">
            <div className="row">
              {features.map((option, index) => (
                <div className="col-md-6" key={index}>
                  {" "}
                  {/* Adjust the col size as needed */}
                  <div className="feature-item p-4">
                    <div className="features-icons-icon d-flex justify-content-center mb-3">
                      <i className={option.icon} style={iconStyles}></i>{" "}
                      {/* Ensure your iconStyles are appropriately defined or use specific classes */}
                    </div>
                    <h3 className="text-center">{option.header}</h3>
                    <p className="text-muted text-center">{option.desc}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Column for feature image */}
          <div className="col-md-6">
            {/* Assuming you have a feature image to place here */}
            <img
              src="/images/background-image.jpg"
              className="img-fluid"
              alt="by Florian Klauer on Unsplash"
            />
            
  
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
