import React, { createContext, useContext } from "react";
import useModuleState from "../hooks/useModuleState";

const StepContext = createContext();

export const useStep = () => useContext(StepContext);

export const StepProvider = ({ children, module }) => {

  const moduleState = useModuleState(module);
  const {
    articleState,
    currentStep,
    setCurrentStep,
    loading,
    setLoading,
    stepsCompleted,
    setStepsCompleted,
    setArticleSummary, // or setSummary based on your state naming
    updateArticle, // or updateSummary based on your state naming
    setValue,
    handleGenerateSummary,
    // Add other states and functions you need from moduleState
  } = moduleState;

  // console.log("currentStep: ", currentStep);

  // Function to move to the next step
  const nextStep = () => setCurrentStep(currentStep + 1);
  // Function to move to the previous step
  const prevStep = () => setCurrentStep(currentStep - 1);
  // Function to go to a specific step
  const goToStep = (step) => setCurrentStep(step);

  // Prepare the context value with both state from useArticleState and additional functions for step navigation
  const value = {
    ...articleState,
    // State management functions from useArticleState
    setArticleSummary,
    updateArticle,
    loading,
    setLoading,
    setValue,
    handleGenerateSummary,
    // Step navigation functions
    currentStep,
    setCurrentStep,
    stepsCompleted,
    setStepsCompleted,
    nextStep,
    prevStep,
    goToStep,
  };

  return <StepContext.Provider value={value}>{children}</StepContext.Provider>;
};
