import { React, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { usePreferences } from "./context/PreferencesContext";
import CreateUserProfileForm from "./components/CreateUserProfileForm";

// Importing new and updated components
import NavBar from "./components/NavBar";
import WritingModules from "./components/WritingModules";
import Footer from "./components/FooterLite";

const ModulesLandingPage = () => {
  const {
    modulesActive,
    isProfileCreationActive,
    setProfileDataContext,
    setIsProfileCreationActive,
  } = usePreferences();
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated && !isProfileCreationActive) {
      // Optionally, check for a specific destination in local storage or state
      // If exists, navigate to that destination, else stay on /modules
      // Example: const destination = localStorage.getItem('destination') || '/modules';
      // navigate(destination);
    }
    // console.log(isProfileCreationActive)
  }, [isAuthenticated, navigate, isProfileCreationActive]);

  const handleProfileSave = (profileData) => {
    // Update the application state with the new user profile
    setIsProfileCreationActive(false);
    setProfileDataContext(profileData);
    // Optionally, redirect the user or display a success message
    // console.log("Profile saved successfully!");
    // For redirection, you can use: history.push('/dashboard') if you're using react-router
    navigate("/modules", { replace: true });
    // Then reload the page
    window.location.reload();
  };

  return (
    <div className="modules-landing-page main">
      <NavBar />
      <div>
        {isProfileCreationActive ? (
          <CreateUserProfileForm onSave={handleProfileSave} />
        ) : (
          <WritingModules modulesActive={modulesActive} />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ModulesLandingPage;
