import React from "react";
import useModuleState from "../../hooks/useModuleState";
/**
 * Slider Component
 *
 * A generic component for rendering a slider input field with a label and description.
 *
 * Props:
 * - label (string): The label for the slider input field.
 * - description (string): A brief description of what the slider input is for.
 * - value (number): The current value of the slider input.
 * - setValue (function): The function to call when the value changes.
 * - min (number): The minimum value of the slider.
 * - max (number): The maximum value of the slider.
 * - animationClass (string): Optional. A class for animations or additional styling.
 */
const Slider = ({
  label,
  description,
  min,
  max,
  animationClass,
  styleName,
  module,
}) => {
  const moduleState = useModuleState(module);

  const { articleState, setValue } = moduleState;
  // Use local state for immediate feedback
  const [localValue, setLocalValue] = React.useState(
    articleState[styleName] || min
  );

  // Update local state on slider change
  const handleSliderChange = (e) => {
    const newValue = Number(e.target.value);
    setLocalValue(newValue); // Immediate feedback
    setValue(styleName, newValue); // Update global state
  };

  // Ensure local state is updated if articleState changes externally
  React.useEffect(() => {
    setLocalValue(articleState[styleName] || min);
  }, [articleState, styleName, min]);

  // console.log(articleState[styleName]); // For debugging

  return (
    <div
      className={`form-group form-group-slider ${animationClass}`}
      style={{ minHeight: "300px" }}
    >
      <div className="row">
        <div className="col-md-8">
          <h2>
            {label}: <label>{localValue}</label>
          </h2>
          <small
            className="form-text text-muted"
            style={{ paddingBottom: "20px" }}
          >
            {description}
          </small>
        </div>
        <div className="col-md-4">
          <div className="bullet-list-images mt-3">
            {Array.from({ length: localValue }, (_, index) => (
              <img
                key={index}
                src="/images/bullet_list.png"
                className="bullet-image"
                alt=""
              />
            ))}
          </div>
        </div>
      </div>
      <div className="slider-container">
        {" "}
        {/* Use the new class here */}
        <input
          type="range"
          className="form-range"
          value={localValue}
          name={styleName}
          onChange={handleSliderChange}
          min={min}
          max={max}
        />
      </div>
    </div>
  );
};

export default Slider;
