// src/admin/UsersAdmin.js
import React, { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import UsersTable from "./UsersTable"; // Make sure this path matches your UsersTable component location
import EditUserModal from "./EditUserModal";

const UsersAdmin = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [editableUser, setEditableUser] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiPort = process.env.REACT_APP_API_PORT;
  const base_url = `${apiUrl}:${apiPort}`;

  const fetchUsers = useCallback(async () => {
    setIsLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(`${base_url}/api/v1/admin/users`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      if (!response.ok) throw new Error("Failed to fetch users");
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setIsLoading(false);
    }
  }, [getAccessTokenSilently, base_url]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleShowEditModal = (user) => {
    setEditableUser(user);
    setShowEditModal(true);
  };

  const handleHideEditModal = () => {
    setShowEditModal(false);
    setEditableUser(null);
  };

  const handleUpdateUserDetails = async (updatedUser) => {
    setIsLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      await fetch(`${base_url}/api/v1/admin/users/${updatedUser._id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(updatedUser),
      });

      await fetchUsers(); // This will now work as fetchUsers is defined outside useEffect
      handleHideEditModal();
    } catch (error) {
      console.error("Error updating user:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <div>Loading users...</div>;

  return (
    <div>
      <h2>Users Administration</h2>
      <p>Here you can manage your users.</p>
      <UsersTable users={users} onEdit={handleShowEditModal} />
      {showEditModal && editableUser && (
        <EditUserModal
          show={showEditModal}
          user={editableUser}
          onHide={handleHideEditModal}
          onSave={handleUpdateUserDetails}
        />
      )}
    </div>
  );
};

export default UsersAdmin;
