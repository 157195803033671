// src/admin/ProductsAdmin.js
import React, { useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const ProductsAdmin = () => {
  const [modules, setModules] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
//   const [editableModule, setEditableModule] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiPort = process.env.REACT_APP_API_PORT;
  const base_url = `${apiUrl}:${apiPort}`;

  const fetchModules = useCallback(async () => {
    setIsLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(`${base_url}/api/v1/admin/modules`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      if (!response.ok) throw new Error("Failed to fetch users");
      const data = await response.json();
      setModules(data);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setIsLoading(false);
    }
  }, [getAccessTokenSilently, base_url]);

  useEffect(() => {
    fetchModules();
  }, [fetchModules]);
  if (isLoading) return <div>Loading users...</div>;

  return (
    <div>
      <h2>Products Administration</h2>
      <p>Here you can manage your products.</p>
      {/* Products management functionality goes here */}
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Header</th>
              <th>Description</th>
              <th>Image</th>
              <th>Route</th>
              <th>Version</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {modules.map((module, index) => (
              <tr key={index}>
                <td>{module.header}</td>
                <td>{module.desc}</td>
                <td>
                  <img
                    src={`/images/${module.image}`}
                    alt={module.name}
                    style={{ width: "50px" }}
                  />
                </td>
                <td>{module.route}</td>
                <td>{module.version}</td>
                <td>{module.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductsAdmin;
