// import React, { useContext } from 'react';
import { useArticleState } from '../context/ArticleStateContext';
import { useStoryState } from '../context/StoryStateContext';

function useModuleState(module) {
  const articleState = useArticleState();
  const storyState = useStoryState();
  
  // Instead of switching between hooks, switch between states
  switch (module) {
    case "articles":
      return articleState;
    case "stories":
      return storyState;
    default:
      throw new Error('Invalid module type');
  }
}

export default useModuleState;
