import React from "react";
// Assume useNavigate is used for navigation in React Router v6
import { useNavigate } from "react-router-dom";

const ContentTable = ({ data, handleDelete }) => {
  const navigate = useNavigate();

  const handleRowClick = (module, contentId) => {
    // Placeholder for navigation logic. Adjust as needed.
    navigate(`/${module}Module/${contentId}`);
  };

  // const handleDelete = (contentId) => {
  //   console.log("DELETE: ", contentId);
  // }

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const formatBodyText = (bodyArray) => {
    if (!Array.isArray(bodyArray) || bodyArray.length === 0) return "";

    const firstElement = bodyArray[0];
    const startIndex = firstElement.indexOf(":");

    // Determine the start position for substring extraction
    const startPosition = startIndex === -1 ? 0 : startIndex + 2;

    // Extract the substring starting from the calculated position and limit to 20 characters
    const trimmedText = firstElement
      .substring(startPosition)
      .trim()
      .substring(0, 40);

    return `${trimmedText}...`;
  };

  return (
    <div className="container mb-5 mt-5 pb-5">
      <div className="table-responsive">
        <h2>Saved Content</h2>
        <table className="table">
          <thead>
            <tr>
              <th>Title</th>
              <th>Module</th>
              <th>Summary</th>
              <th>Body</th>
              <th>Created</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{formatBodyText(item.body)}</td>
                <td>{item.moduleType}</td>
                <td>{item.summaryCount}</td>
                <td>{item.bodyCount}</td>
                <td>{formatDate(item.createdAt)}</td>
                <td>
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      type="button"
                      className="btn btn-success btn-sm"
                      onClick={() => handleRowClick(item.moduleType, item._id)}
                    >
                      <i className="bi bi-box-arrow-up-right"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      onClick={() => handleDelete(item._id)}
                    >
                      <i className="bi bi-trash"></i>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ContentTable;
