import TextBox from "./components/steps/TextBox";
import CardSelector from "./components/steps/CardSelector";

export const totalSteps = 5; // Define the total number of steps in the process
export const requireAuth = true;

export const stepsConfig = ({
  toneOptions,
  settingOptions,
  characterOptions,
  chapterStructureOptions,
  parentToggle,
}) => [
  {
    component: TextBox,
    props: {
      label: "Story Plot",
      description: "What can I help you write today?",
      placeholder:
        "e.g. A young girl in a dystopian society discovers she doesn't fit into any of its virtue-based factions. Labeled as Divergent, she must navigate a dangerous world to uncover a plot threatening her kind while fighting for survival.",
      animationClass: "slide-in",
      parentToggle: parentToggle,
      styleName: "plot",
      value: "",
      module: "stories"
    },
  },
  {
    component: CardSelector,
    props: {
      title: "Story Tone",
      description: "What is the tone of the story like?",
      options: toneOptions,
      animationClass: "slide-in",
      styleName: "tone",
      value: "",
      module: "stories"
    },
  },
  {
    component: CardSelector,
    props: {
      title: "Story Setting",
      description: "Where does your story take place?",
      options: settingOptions,
      animationClass: "slide-in",
      styleName: "setting",
      value: "",
      module: "stories"
    },
  },
  {
    component: CardSelector,
    props: {
      title: "Story Hero",
      description: "What kind of hero does your story have?",
      options: characterOptions,
      animationClass: "slide-in",
      styleName: "character",
      value: "",
      module: "stories"
    },
  },
  {
    component: CardSelector,
    props: {
      title: "Chapter Structure",
      description: "How do you want to tell the story?",
      options: chapterStructureOptions,
      animationClass: "slide-in",
      styleName: "chapters",
      value: "",
      module: "stories"
    },
  },
  // Add other steps as needed
];
