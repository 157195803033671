import { driver } from "driver.js";

// Initialize the Driver with tour 1
const tour1 = {
  animate: false,
  showProgress: false,
  showButtons: ["next", "previous"],
  popoverClass: "driverjs-theme",
  steps: [
    {
      element: "#tour-start",
      popover: {
        title: "Welcome to 👻 Writr.",
        description: "This is Part 1 of the 👻 Writr interface.",
        side: "left",
        align: "start",
      },
    },
    {
      element: "#tour-step-toggle",
      popover: {
        title: "Toggle Tours",
        description:
          "You can access key functions here. Specifically 👻 Writr tours, feedback and reset options.",
        side: "right",
        align: "start",
      },
    },
    {
      element: "#tour-step-1",
      popover: {
        title: "Article Sections",
        description:
          "This is the proposed contents 👻 Writr generated for the article.",
        side: "right",
        align: "start",
      },
    },
    {
      element: "#tour-step-2",
      popover: {
        title: "Article Contents Section",
        description:
          "This is an overview of what you will pass to 👻 Writr to generate.",
        side: "bottom",
        align: "start",
      },
    },
    {
      element: "#tour-step-3",
      popover: {
        title: "Article Contents Section Actions",
        description: "This is the actions bar for the section.",
        side: "left",
        align: "start",
      },
    },
    {
      element: "#tour-step-4",
      popover: {
        title: "Generate Section",
        description:
          "Clicking this button will generate the contents of the article you are creating.",
        side: "top",
        align: "center",
      },
    },
    {
      element: "#tour-step-5",
      popover: {
        title: "Edit Section",
        description:
          "The Edit button gives you the option to alter the contents section to add/change/remove items to generate.",
        side: "right",
        align: "center",
      },
    },
    {
      element: "#tour-step-6",
      popover: {
        title: "Article Body",
        description:
          "This is where your article will generate. Go ahead and start generating content.",
        side: "right",
        align: "start",
      },
    },
    {
      popover: {
        title: "Happy Writing",
        description:
          "Thanks for taking the time to use the 👻 Writr App. Now generate some content. You can access the next tour in the menu. <center><img src='https://media.giphy.com/media/eJG93okDlbbMSWreuz/giphy.gif' alt='happy writing' width='100%' /></center>",
      },
    },
  ],
};

// Initialize the Driver with tour 2
const tour2 = {
  animate: false,
  showProgress: false,
  showButtons: ["next", "previous"],
  popoverClass: "driverjs-theme",
  steps: [
    {
      element: "#tour-start",
      popover: {
        title: "Welcome to 👻 Writr.",
        description:
          "This Second part of the 👻 Writr interface tour. Let's dive into the Article Body functions",
        side: "left",
        align: "start",
      },
    },
    {
      element: "#tour-step-7",
      popover: {
        title: "Article Section",
        description: "This is the generated section of the article.",
        side: "right",
        align: "start",
      },
    },
    {
      element: "#tour-step-8",
      popover: {
        title: "Article Section Toolbar",
        description: "This is the toolbar for the section.",
        side: "top",
        align: "center",
      },
    },
    {
      element: "#tour-step-9",
      popover: {
        title: "Article Actions - Regnerate",
        description:
          "If you are not happy with the content generated, you can regenerate it.",
        side: "top",
        align: "start",
      },
    },
    {
      element: "#tour-step-10",
      popover: {
        title: "Article Actions - Move",
        description: "If you have multiple sections, you can rearrange them.",
        side: "top",
        align: "center",
      },
    },
    {
      element: "#tour-step-11",
      popover: {
        title: "Article Actions - Delete",
        description:
          "If you want to remove or start over with a particular section you can delete it here.",
        side: "top",
        align: "end",
      },
    },
    {
      element: "#tour-step-12",
      popover: {
        title: "Wrapping Up",
        description:
          "If you want to generate a conclusion or simply export the whole of the article you have, there are the buttons you need 😉",
        side: "top",
        align: "start",
      },
    },
    {
      popover: {
        title: "Happy Writing",
        description:
          "Thanks for taking the time to use the 👻 Writr App. You can provide feedback and take the Article tours from the menu. <center><img src='https://media.giphy.com/media/H1dxi6xdh4NGQCZSvz/giphy.gif' alt='happy writing' width='100%' /></center>",
      },
    },
  ],
};

// export { tour1, tour2 };
export function getTour(id) {
  const numericId = Number(id); // Convert id to a number if it's not already

  // console.log("Requested tour ID:", numericId, "Type:", typeof numericId); // Debugging line

  if (numericId === 1) {
    const userTour = driver(tour1);
    return userTour;
  } else if (numericId === 2) {
    const userTour = driver(tour2);
    return userTour;
  }
  return null; // Return null if no matching tour is found
}
