import React from 'react';

/**
 * ProgressBar Component
 * 
 * Displays a progress bar based on the current step out of the total number of steps in a process.
 * This component is designed to be reusable for any process requiring a progress indicator.
 * 
 * Props:
 * - currentStep (number): The current step number in the process.
 * - totalSteps (number): The total number of steps in the process.
 * 
 * The progress percentage is calculated based on the current step divided by the total steps,
 * and it visually represents how far along the process is. This component is styled with Bootstrap
 * classes by default, but you can customize the styling as needed.
 * 
 * Usage:
 * <ProgressBar currentStep={currentStep} totalSteps={totalSteps} />
 * 
 * Ensure you have Bootstrap included in your project for the progress bar styles to apply,
 * or modify the classNames and styles to fit your project's styling framework.
 */
const ProgressBar = ({ currentStep, totalSteps }) => {
  // Calculate the width of the progress bar as a percentage of the total steps
  const progressPercentage = (currentStep / totalSteps) * 100;

  return (
    <div className="progress" style={{ height: "10px", marginBottom: "20px" }}>
      <div
        className="progress-bar overflow-visible text-dark"
        role="progressbar"
        style={{ width: `${progressPercentage}%`, height: "10px" }}
        aria-valuenow={progressPercentage}
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  );
};

export default ProgressBar;
