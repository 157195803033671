import React, { useEffect } from "react";
import { useStep } from "../context/StepContext";
import useModuleState from "../hooks/useModuleState";

import StepNavigation from "./StepNavigation";
import ProgressBar from "./ProgressBar";
import ArticleSummary from "./ArticleSummary";
import ArticleBody from "./ArticleBody";

const StepManager = ({ steps, module, data }) => {
  const moduleState = useModuleState(module);

  const {
    currentStep,
    setCurrentStep,
    handleGenerateSummary,
    stepsCompleted,
    setStepsCompleted,
    handleBack,
  } = useStep();
  const totalSteps = steps.length;

  const {
    articleSummary,
    setArticleSummary,
    checkRequired,
    copyTextareaRef,
    updateArticle,
    setUpdateArticle,
    showToast,
    handleAddConclusion,
    accessToken,
    base_url,
    docId,
    setDocId,
  } = moduleState;

  const url = base_url;
  const splitUrl = url.split("/api"); // This splits the string into an array using "/api" as the delimiter
  const baseUrl = splitUrl[0]; // The first element of the array contains the URL part before "/api"

  const handleCopyToClipboard = () => {
    // Check if there is content to copy
    if (updateArticle.length > 0) {
      const formattedContent = updateArticle.join("\n\n");
      navigator.clipboard.writeText(formattedContent).then(
        () => {
          // Display success toast message
          showToast("Article copied to clipboard", "success");
        },
        (err) => {
          console.error("Could not copy text: ", err);
        }
      );
    } else {
      // Display toast message indicating the need to generate content
      showToast(
        "Please generate content before using this feature.",
        "warning"
      );
    }
  };

  useEffect(() => {
    if (data) {
      setStepsCompleted(true);
      setArticleSummary(data.summary);
      setUpdateArticle(data.body);
      setDocId(data._id);
    }
  }, [data, setStepsCompleted, setArticleSummary, setUpdateArticle, setDocId]);

  const handleSaveContent = async () => {
    if (updateArticle.length > 0) {
      console.log("docId", docId);

      const payload = {
        moduleType: module,
        summary: articleSummary,
        body: updateArticle,
      };

      const endpoint = docId
        ? `/api/v1/users/content/${docId}`
        : `/api/v1/users/content`; // Adjusted endpoint based on docId
      const method = docId ? "PATCH" : "POST"; // Use PATCH for update and POST for create

      try {
        const response = await fetch(`${baseUrl}${endpoint}`, {
          // Use base_url directly assuming it includes /api
          method: method,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error("Failed to save generated content");
        }

        const data = await response.json();

        // Update docId with the returned documentId for updates, or with the newly created documentId
        if (data.documentId) {
          // console.log("Generated content saved successfully", data);
          showToast("Generated content saved successfully", "info");
          setDocId(data.documentId); // Update state with the new or existing documentId
        }
      } catch (error) {
        console.error("Error saving generated content:", error);
      }
    } else {
      // Display a message indicating the need to generate content before saving
      showToast(
        "Please generate content before using this feature.",
        "warning"
      );
    }
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // console.log(articleSummary)
  // Assuming stepsCompleted is updated to true correctly
  // Adjust your rendering logic here
  if (stepsCompleted) {
    // Render ArticleSummary and ArticleBody when steps are completed
    return (
      <div>
        <hr />
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-md-4">
            <div className="articleSummary" id="tour-step-1">
              <h2>{capitalizeFirstLetter(module)} Sections</h2>
              <small
                className="form-text text-muted"
                style={{ paddingBottom: "10px" }}
              >
                This is like a table of contents.
                <br />
                Edit & generate your article parts here.
              </small>
              <hr />
              <div
                className="col-md-auto articleSummary-section"
                id="tour-step-12"
              >
                <div
                  className="btn-toolbar justify-content-center "
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                >
                  <div
                    className="btn-group me-2"
                    role="group"
                    aria-label="Second group"
                    style={{ paddingTop: "10px" }}
                  >
                    <button
                      type="button"
                      className="btn btn-success btn-sm"
                      onClick={handleAddConclusion}
                    >
                      Add Conclusion
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary btn-copy btn-sm"
                      onClick={handleCopyToClipboard}
                    >
                      Copy to Clipboard
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary btn-copy btn-sm"
                      onClick={handleSaveContent}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
              {articleSummary &&
                articleSummary.length > 0 &&
                articleSummary.map((section, index) => (
                  <ArticleSummary
                    key={index}
                    content={section.content}
                    // handleGenerateArticleSection={handleGenerateArticleSection}
                    index={index}
                    module={module}
                    // handleContentChange={handleContentChange}
                  />
                ))}
            </div>
          </div>
          <div className="col-md-8">
            <div className="updateArticle" id="tour-step-6">
              <h2>{capitalizeFirstLetter(module)} Body</h2>
              <small
                className="form-text text-muted"
                style={{ paddingBottom: "30px" }}
              >
                This the content area you are building from the above sections.
                <br />
                Treat this like the article you are writing.
              </small>
              <hr />
              <ArticleBody module={module} />
            </div>
          </div>
          <textarea
            ref={copyTextareaRef}
            style={{ position: "absolute", left: "-9999px", opacity: "0" }}
            readOnly
          />
        </div>
      </div>
    );
  } else {
    // Render the steps as normal
    const CurrentStepComponent = steps[currentStep - 1]?.component;
    const stepProps = steps[currentStep - 1]?.props;

    return (
      <div>
        {currentStep <= totalSteps && (
          <ProgressBar currentStep={currentStep} totalSteps={totalSteps} />
        )}
        {CurrentStepComponent && (
          <CurrentStepComponent {...stepProps} module={module} />
        )}
        <StepNavigation
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          totalSteps={totalSteps}
          handleBack={handleBack}
          handleGenerateSummary={handleGenerateSummary}
          checkRequired={checkRequired}
        />
      </div>
    );
  }
};

export default StepManager;
