// src/analytics.js
import ReactGA from "react-ga4";

export const initGA = () => {
  ReactGA.initialize("G-MHT9BKZJKH"); // Replace 'YOUR_MEASUREMENT_ID' with your actual Measurement ID
};

export const trackPageView = (path) => {
  ReactGA.send({ hitType: "pageview", page: path });
};

// Set custom dimensions, e.g., traffic_type
export const setCustomDimension = (index, value) => {
  index===1 && ReactGA.gtag('event', 'custom_dimension', {'traffic_type': value}); //ReactGA.gtag({ traffic_type: value });
};