// src/admin/EditUserModal.js
import React, { useState, useEffect } from 'react';

const EditUserModal = ({ show, user, onHide, onSave }) => {
  const [userData, setUserData] = useState(user);
    console.log(user)
  useEffect(() => {
    setUserData(user); // Update local state when the user prop changes
  }, [user]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUserData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  return (
    <div className={`modal ${show ? 'd-block' : 'd-none'}`} tabIndex="-1">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit User</h5>
            <button type="button" className="btn-close" onClick={onHide}></button>
          </div>
          <div className="modal-body">
            {/* Form fields */}
            <form>
              <div className="mb-3">
                <label htmlFor="userEmail" className="form-label">Email</label>
                <input type="email" className="form-control" id="userEmail" name="email" value={userData.email} onChange={handleChange} />
              </div>
              <div className="mb-3">
                <label htmlFor="firstName" className="form-label">First Name</label>
                <input type="text" className="form-control" id="firstName" name="firstName" value={userData.profile.firstName} onChange={handleChange} />
              </div>
              <div className="mb-3">
                <label htmlFor="lastName" className="form-label">Last Name</label>
                <input type="text" className="form-control" id="lastName" name="lastName" value={userData.profile.lastName} onChange={handleChange} />
              </div>
              {/* Add other fields as needed */}
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onHide}>Close</button>
            <button type="button" className="btn btn-primary" onClick={() => onSave(userData)}>Save changes</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUserModal;
