// LeftMenu.js
import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react"; // Assuming you're using Auth0 for authentication
import useModuleState from "../hooks/useModuleState";
import { getTour } from "../utils/driverInstance";
import ToggleButton from "../utils/TourToggle"; // Adjust the path based on your file structure
import ModuleButtons from "../utils/ModuleButtons";
import { usePreferences } from "../../context/PreferencesContext";
import "../../css/LeftMenu.css"; // Assuming you'll create a CSS file for custom styles

const LeftMenu = ({ module }) => {
  const { appPreferences, setDBAppPreferences, profileDataContext } =
    usePreferences();
  const { user } = useAuth0();
  const userType = profileDataContext.userType;

  const moduleState = useModuleState(module);
  const { stepsCompleted } = moduleState;

  const { logout } = useAuth0();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [firstTime, setFirstTime] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Automatically close the sidebar on smaller screens when initially loading the component
  useEffect(() => {
    if (windowWidth > 1024 && !isSidebarOpen) {
      setIsSidebarOpen(true);
      setFirstTime(true);
    } else if (windowWidth <= 1024 && firstTime) {
      setIsSidebarOpen(false);
    }

    // console.log(firstTime)
  }, [windowWidth, isSidebarOpen,firstTime]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setFirstTime(!firstTime);
  };

  const handleTourToggle = () => {
    setDBAppPreferences(!appPreferences.tour);
  };

  return (
    <>
      <button className="hamburger-menu" onClick={toggleSidebar}>
        &#9776;
      </button>
      <div
        className={`col-m-2 col-l-2 col-xl-2 px-sm-2 px-0 bg-light d-flex sidebar ${
          isSidebarOpen ? "open" : ""
        } ${windowWidth > 1024 ? "permanent-open sticky-top " : ""}`}
      >
        <div className="sidebar-content align-items-center px-3 pt-2 text-dark">
          <a className="brand" href="/">
            👻 Writr.
          </a>
          <hr />
          <ToggleButton
            isToggled={appPreferences.tour}
            onToggle={handleTourToggle}
          />
          <ModuleButtons />
          <hr />
          <ul className="nav nav-pills flex-column mb-auto">
            <li className="nav-item">
              {stepsCompleted && (
                <a
                  className="nav-link link-body-emphasis"
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    getTour(1).drive();
                  }}
                >
                  <i
                    className="bi bi-ticket-perforated me-2"
                    style={{ fontSize: "1rem" }}
                  ></i>
                  <span className="ms-1 d-none d-sm-inline">Step 1 Tour</span>
                </a>
              )}
            </li>
            <li className="nav-item">
              {stepsCompleted && (
                <a
                  className="nav-link link-body-emphasis"
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    getTour(2).drive();
                  }}
                >
                  <i
                    className="bi bi-ticket-perforated me-2"
                    style={{ fontSize: "1rem" }}
                  ></i>
                  <span className="ms-1 d-none d-sm-inline">Step 2 Tour</span>
                </a>
              )}
            </li>
            <li>
              <a
                href="https://forms.gle/zW4x1Qdfdrp64VXo9"
                target="_blank"
                rel="noopener noreferrer"
                className="nav-link link-body-emphasis"
              >
                <i
                  className="bi bi-voicemail me-2"
                  style={{ fontSize: "1rem" }}
                ></i>
                <span className="ms-1 d-none d-sm-inline">Beta Feedback</span>
              </a>
            </li>
          </ul>
          <hr />
          <div className="dropdown py-sm-4 mt-sm-auto ms-auto ms-sm-0 flex-shrink-1">
            <a
              href="void()"
              className="d-flex align-items-center text-dark text-decoration-none dropdown-toggle"
              id="dropdownUser1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src={profileDataContext.profile.avatarUrl || user.picture}
                alt=""
                width="32"
                height="32"
                className="rounded-circle me-2"
              />
              <strong>{profileDataContext.profile.firstName}</strong>
            </a>
            <ul
              className="dropdown-menu dropdown-menu-light text-small shadow"
              aria-labelledby="dropdownUser1"
            >
              {userType === "admin" && (
              <li className="nav-item">
                <a href="/admin" className="dropdown-item">
                  Admin Dash
                </a>
              </li>
            )}
              <li className="nav-item">
                <a href="/profile" className="dropdown-item">
                  Profile
                </a>
              </li>
              <li className="nav-item">
                <a href="/modules" className="dropdown-item">
                  Writing Modules
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <a
                  className="dropdown-item"
                  href="/logout"
                  onClick={(e) => {
                    e.preventDefault(); // Updated for clarity
                    logout({ returnTo: window.location.origin });
                  }}
                >
                  Sign out
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftMenu;
