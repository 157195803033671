import React from "react";
import useModuleState from "../../hooks/useModuleState";
import { usePreferences } from "../../../context/PreferencesContext";

import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import "../../utils/tour.css";
/**
 * TextBox Component
 *
 * A generic component for rendering a text input field with a label and description.
 *
 * Props:
 * - label (string): The label for the text input field.
 * - description (string): A brief description of what the text input is for.
 * - value (string): The current value of the text input.
 * - setValue (function): The function to call when the value changes.
 * - placeholder (string): Placeholder text for the text input field.
 * - animationClass (string): Optional. A class for animations or additional styling.
 */
const TextBox = ({
  label,
  description,
  placeholder,
  animationClass,
  styleName,
  module
}) => {
  function SimpleHighlight(id) {
    const driverObj = driver({
      popoverClass: "driverjs-theme",
      stagePadding: 0,
      onDestroyed: () => {
        document?.activeElement?.blur();
      },
    });

    driverObj.highlight({
      element: `#${id}`,
      popover: {
        title: "Enter a Topic",
        description:
          "This really sets the tone of the article. Dont be afraid to say something prolific.",
        align: "start",
      },
    });
    
  }
  const moduleState = useModuleState(module);

  const { articleState, setValue } = moduleState;
  const { appPreferences } = usePreferences();

  return (
    <div className={`form-group form-section ${animationClass}`}>
      <h4>
        <label htmlFor="topicInputText">{label}</label>
      </h4>
      <small className="form-text text-muted" style={{ paddingBottom: "20px" }}>
        {description}
      </small>
      <textarea
        className="form-control"
        id="topicInputText"
        rows="2"
        value={articleState[styleName]}
        onChange={(e) => setValue(styleName, e.target.value)}
        onFocus={(e) => appPreferences.tour && SimpleHighlight(e.target.id)}
        placeholder={placeholder}
        style={{ marginBottom: "20px" }}
      />
    </div>
  );
};
export default TextBox;
