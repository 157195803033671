const Footer = ({
  updateArticle,
  handleAddConclusion,
  handleCopyToClipboard,
}) => {
  return (
    <footer className="footer-module row py-4 mt-auto">
      <div className="container-fluid">
        <div className="row justify-content-center align-items-center">
          {/* Copyright Information */}
          <div className="col-md-auto">
            <p className="mb-0">
              &copy; 2024 Ghost Writr. All rights reserved. 
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
