// src/App.js
// App.js
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import LandingPage from "./LandingPage";
import Profile from "./Profile";
import AdminDashboard from "./admin/Admin";
import UsersAdmin from "./admin/UsersAdmin";
import ProductsAdmin from "./admin/ProductsAdmin";
import ConfigAdmin from "./admin/ConfigAdmin";
import ModulesLandingPage from "./ModulesLandingPage";
import ArticlesModule from "./modules/Articles";
import StoriesModule from "./modules/Stories";
import Loading from "./Loading";
import { ArticleConfig, StoriesConfig } from "./ModulesConfig";

const App = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  // console.log(ArticleConfig)

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Router>
      <Routes>
        {" "}
        {/* Ensure Routes is used to wrap Route definitions */}
        <Route
          path="/"
          element={
            !isAuthenticated ? (
              <LandingPage />
            ) : (
              <Navigate replace to="/modules" />
            )
          }
        />
        <Route
          path="/modules"
          element={
            isAuthenticated ? (
              <ModulesLandingPage />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
          <Route path="/admin" element={isAuthenticated ? <AdminDashboard /> : <Navigate replace to="/" />}>
            <Route path="users" element={isAuthenticated ? <UsersAdmin /> : <Navigate replace to="/" />}/>
            <Route path="products" element={isAuthenticated ? <ProductsAdmin /> : <Navigate replace to="/" />}/>
            <Route path="config" element={isAuthenticated ? <ConfigAdmin /> : <Navigate replace to="/" />}/>
         </Route>

        <Route
          path="/profile"
          element={isAuthenticated ? <Profile /> : <Navigate replace to="/" />}
        />
        {/* <Route path="/profile"  element={<Profile />}  /> */}
        <Route
          path="/articlesModule/:_id?"
          element={
            isAuthenticated ? (
              <ArticlesModule config={ArticleConfig} />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
        <Route
          path="/storiesModule/:_id?"
          element={
            isAuthenticated ? (
              <StoriesModule config={StoriesConfig} />
            ) : (
              <Navigate replace to="/" />
            )
          }
        />
        {/* Define other routes and protected routes as needed */}
      </Routes>
    </Router>
  );
};

export default App;
