import React from "react";
import { useAuth0 } from "@auth0/auth0-react"; // Assuming you're using Auth0 for authentication
import { usePreferences } from "../context/PreferencesContext";

const NavBar = () => {
  const { logout } = useAuth0();
  const { profileDataContext } = usePreferences();
  const userType = profileDataContext.userType;

  return (
    <nav className="navbar navbar-lp sticky-top">
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
            👻 Writr.
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
            {userType === "admin" && (
              <li className="nav-item">
                <a href="/admin" className="nav-link">
                  Admin Dash
                </a>
              </li>
            )}

            <li className="nav-item">
              <a href="/profile" className="nav-link">
                Profile
              </a>
            </li>
            <li className="nav-item">
              <a href="/modules" className="nav-link">
                Writing Modules
              </a>
            </li>

            <li>
              <a
                href="https://forms.gle/zW4x1Qdfdrp64VXo9"
                target="_blank"
                rel="noopener noreferrer"
                className="nav-link"
              >
                Beta Feedback
              </a>
            </li>
            <hr />
            <li className="nav-item">
              <a
                className="nav-link"
                href="/logout" // Changed to a more semantic placeholder; actual href is prevented.
                onClick={(e) => {
                  e.preventDefault(); // Prevent the default anchor action
                  logout({ returnTo: window.location.origin });
                }}
              >
                Log Out
              </a>
            </li>
            <hr />
          </ul>
        </div>
      </div>

      <div className="container-fluid">
        <a className="navbar-brand navbar-brand-lp" href="/">
          👻 Writr.
        </a>
        <button
          id="tour-step-toggle"
          className="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>
    </nav>
  );
};

export default NavBar;
