// In the parent component (e.g., App.js or any component that renders Articles)
// export const ArticleConfig = {
//     appTitle: "Articles",
//     apiEndpoint: "https://example.com/api/articles",
//     requireAuth: true,
//     layout: "grid",
//     // Add other configuration settings as needed

// };

export const ArticleConfig =  {
    appTitle: "Articles",
    apiEndpoint: "/api/articles",
    requireAuth: true,
    layout: "grid",
    // Add other configuration settings as needed
};

export const StoriesConfig =  {
    appTitle: "Stories",
    apiEndpoint: "/api/stories",
    requireAuth: true,
    layout: "grid",
    // Add other configuration settings as needed
};

export const EssaysConfig =  {
    appTitle: "Essays",
    apiEndpoint: "/api/essays",
    requireAuth: true,
    layout: "grid",
    // Add other configuration settings as needed
};
