/**
 * ArticleSectionPart.js
 * This component represents a single section of an article within the application. It is designed to display
 * the content of the article section and provide editing functionalities, such as regenerating the content,
 * deleting the section, or moving the section up or down within the article. The component is a key part of
 * the article editing experience, allowing users to interact with individual sections of their article in a
 * dynamic and intuitive manner.
 *
 * Props:
 * - content: The text content of the article section. This content is displayed to the user and can be edited.
 * - handleRegenerateArticleSection: Function to regenerate the content of this section. This might be used
 *   when the user wants to automatically rewrite or improve the section based on certain criteria.
 * - handleDeleteSection: Function to delete this section from the article. Useful for removing unnecessary or
 *   unwanted sections during the editing process.
 * - moveSectionUp: Function to move this section up in the article's order. Allows for rearranging sections.
 * - moveSectionDown: Function to move this section down in the article's order. Enables users to organize their
 *   article structure.
 * - index: The index of this section within the article. Used for displaying section order and handling moves.
 * - totalSections: The total number of sections in the article. Helps determine when moving up or down is allowed.
 * - isConclusion: A boolean indicating if this section is considered the conclusion of the article. This can affect
 *   how the section is displayed or if certain actions are restricted.
 *
 * This component contributes to a modular and flexible article editing interface, where users can focus on
 * individual sections, tailor their content, and structure the article to their preferences.
 */
import React from "react";
import Markdown from "react-markdown";

const ArticleSectionPart = ({
  content,
  handleRegenerateArticleSection,
  handleDeleteSection,
  moveSectionUp,
  moveSectionDown,
  index,
  totalSections,
  isConclusion,
}) => {
  return (
    <div className="update-articleSummary-section" id="tour-step-7">
      <Markdown>{content}</Markdown>
      <div
        className="btn-toolbar"
        role="toolbar"
        aria-label="Section Controls"
        id="tour-step-8"
      >
        {!isConclusion && (
          <>
            <div
              className="btn-group me-2"
              role="group"
              aria-label="First group"
              id="tour-step-9"
            >
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleRegenerateArticleSection}
              >
                Regenerate
              </button>
            </div>
            <div
              className="btn-group me-2"
              role="group"
              aria-label="First group"
              id="tour-step-10"
            >
              <button
                type="button"
                className="btn btn-secondary"
                onClick={moveSectionUp}
                disabled={index === 0}
              >
                ↑
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={moveSectionDown}
                disabled={index === totalSections - 1}
              >
                ↓
              </button>
            </div>
          </>
        )}
        <div
          className="btn-group me-2"
          role="group"
          aria-label="First group"
          id="tour-step-11"
        >
          <button
            type="button"
            className="btn btn-danger"
            onClick={handleDeleteSection}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default ArticleSectionPart;
