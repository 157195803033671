import React, { useState, useEffect } from "react";
import axios from "axios";

const MediumArticles = () => {
  const [articles, setArticles] = useState([]);
  const desiredTag = "genai";

  useEffect(() => {
    // Replace with your actual CORS proxy if needed. This might be necessary if you're facing CORS issues when making a request to Medium's RSS feed.
    // const CORS_PROXY = "https://corsproxy.io";
    const FEED_URL = "https://medium.com/feed/@joerobens"; // Replace '@yourusername' with your actual Medium username
    const url = "https://corsproxy.io/?" + encodeURIComponent(FEED_URL);
    const fetchArticles = async () => {
      try {
        const response = await axios.get(url, {
          headers: {
            "Content-Type": "application/xml; charset=utf-8",
          },
        });
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(response.data, "text/xml");

        // Parse the XML to find items (articles)
        const items = xmlDoc.getElementsByTagName("item");
        const parsedArticles = Array.from(items).map((item) => {
          // Extract categories
          const categories = Array.from(
            item.getElementsByTagName("category")
          ).map((c) => c.textContent);

          // Extract pubDate
          const pubDate = item.getElementsByTagName("pubDate")[0].textContent;
          const pubDateF = new Date(pubDate);

          // Format the date to "Month Day, Year"
          const formattedPubDate = pubDateF.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          });

          // Extract the content and parse the first image
          let contentEncoded =
            item.getElementsByTagName("content:encoded")[0].textContent;
          let parser = new DOMParser();
          let htmlDoc = parser.parseFromString(contentEncoded, "text/html");
          let firstImage = htmlDoc.querySelector("img")
            ? htmlDoc.querySelector("img").src
            : null;

          return {
            title: item.getElementsByTagName("title")[0].textContent,
            link: item.getElementsByTagName("link")[0].textContent,
            categories: categories,
            pubDate: formattedPubDate,
            firstImage: firstImage,
          };
        });

        setArticles(parsedArticles);
      } catch (error) {
        console.error("Error fetching Medium articles:", error);
      }
    };

    fetchArticles();
  }, []);

  //   console.log(articles[1].categories)
  const filteredArticles = articles.filter((article) =>
    article.categories.some(
      (category) => category.toLowerCase() === desiredTag.toLowerCase()
    )
  );
  filteredArticles.slice(-4); // Get the last 4 articles
  //   console.log(filteredArticles);
  return (
    <div className="container-flex articles-section">
      <h2>
        Articles written with 👻 Writr.
      </h2>
      <div className="d-flex justify-content-center">
        <div className="row">
          {filteredArticles.map((article, index) => (
            <div
              key={index}
              className="col-md-3 mb-4 d-flex align-items-stretch"
            >
              <div className="card w-100">
                <img
                  src={article.firstImage}
                  className="card-img-top"
                  alt={article.title}
                  style={{ height: "200px", objectFit: "cover" }}
                />
                <div className="card-body d-flex flex-column">
                  <h5 className="card-title">{article.title}</h5>
                  <div className="mt-auto">
                    {" "}
                    {/* This div will ensure the button and date are pushed to the bottom */}
                    <div className="d-flex justify-content-between align-items-center">
                      <a
                        href={article.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-primary"
                      >
                        Read More
                      </a>
                      <small className="text-body-secondary">
                        {article.pubDate}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MediumArticles;
