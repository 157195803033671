import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const NavBarLite = () => {
  const { loginWithRedirect, isAuthenticated, logout } = useAuth0();

  return (
    
    <nav className="navbar navbar-expand-sm navbar-lp sticky-top">
      <div className="container-fluid" style={{padding: "0 3rem"}}>
        <a className="navbar-brand navbar-brand-lp" href="/">
          👻 Writr.
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false" 
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav ms-auto">
            {!isAuthenticated && (
              <li className="nav-item">
                <button
                  className="btn btn-primary"
                  onClick={() => loginWithRedirect()}
                >
                  Log In
                </button>
              </li>
            )}
            {isAuthenticated && (
              <li className="nav-item">
                <button
                  className="btn btn-lp"
                  onClick={() => logout({ returnTo: window.location.origin })}
                >
                  Log Out
                </button>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default NavBarLite;
